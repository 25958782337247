import algoliasearch from 'algoliasearch';
import { AlgoliaSearchError } from 'errors/errors';

/**
 * Sample request:
 *
 *   {
 *     algoliaAppId: `<ALGOLIA_APP_ID>`,
 *     algoliaSearchApiKey: `<ALGOLIA_SEARCH_API_KEY>`,
 *     env: `staging`,
 *     params: {
 *       filters: [
 *         {
 *           values: [`engineer`, `developer`],
 *           negate: false,
 *           name: `title`
 *           operator: `OR`
 *         }
 *       ]
 *     }
 *   }
 *
 */

const fetchSearch = function(request) {
  const filters = parseFilters(request.params.filters);
  const params = {...request.params, ...{filters} };
  const index = getIndex(request);

  return index.search(request.query, params)
    .catch(error => {
      throw new AlgoliaSearchError(error.toString());
    });
};

const getIndex = function(request) {
  // TODO: can this be maintained as singleton to avoid reloading Algolia each
  // time we want to run a search?
  const algoliaSearchClient = algoliasearch(
    request.algoliaAppId,
    request.algoliaSearchApiKey
  );

  return algoliaSearchClient.initIndex(`${request.model}_${request.env}`);
};

function parseFilters(filters) {
  if (filters === undefined) {
    return ``;
  }

  return filters.map(getFilter).filter(filter => filter).join(` AND `);
}

function getFilter(filter) {
  // TODO: this removes any filter that passes in an empty array of values
  // since that would automatically lead to know results. Consider if this
  // is the desired default behavior.
  if (!filter.values.length) {
    return null;
  }
  const negate = filter.negate ? `NOT ` : ``;

  const parsedFilter = filter.values.map(value => {
    return `${negate}${filter.name}:"${value}"`;
  }).join(` ${filter.operator} `);
  return `(${parsedFilter})`;
}

export {
  fetchSearch,
  parseFilters,
};
