import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
  createResource,
  getResource,
  getResources,
  updateResource,
  deleteResource,
} from 'actions/actions';

function WithResourceActions(WrappedComponent) {
  class WrappedWithResourceActions extends React.Component {
    render() {
      return <WrappedComponent {...this.props} />;
    }
  }

  WrappedWithResourceActions.propTypes = {
    createResource: PropTypes.func.isRequired,
    getResource: PropTypes.func.isRequired,
    getResources: PropTypes.func.isRequired,
    updateResource: PropTypes.func.isRequired,
    deleteResource: PropTypes.func.isRequired,
  };

  function mapDispatchToProps(dispatch) {
    return {
      createResource: (request) => dispatch(createResource(request)),
      getResource: (request) => dispatch(getResource(request)),
      getResources: (request) => dispatch(getResources(request)),
      updateResource: (request) => dispatch(updateResource(request)),
      deleteResource: (request) => dispatch(deleteResource(request)),
    };
  }

  return connect(null, mapDispatchToProps)(WrappedWithResourceActions);
}

export default WithResourceActions;
