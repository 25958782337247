import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { connectRouter, routerMiddleware } from 'connected-react-router';
import { createLogger } from 'redux-logger';
import thunk from 'redux-thunk';

import { mixpanelMiddleware } from 'middleware/mixpanel';
import sessionExpirationMiddleware from 'middleware/session';
import { rootReducer } from 'reducers/reducers';


const getMiddlewares = function(history, preloadedState, isProd) {
  const loggerMiddleware = createLogger({collapsed: true});

  const developmentMiddlewares = [
    loggerMiddleware,
  ];

  const productionMiddlewares = [
    routerMiddleware(history),
    thunk,
    mixpanelMiddleware,
    sessionExpirationMiddleware,
  ];

  if (isProd) {
    return productionMiddlewares;
  } else {
    return [...productionMiddlewares, ...developmentMiddlewares];
  }
};

const getEnhancer = function(middlewares, isProd) {
  if (isProd) {
    return applyMiddleware(...middlewares);
  } else {
    return composeWithDevTools(applyMiddleware(...middlewares));
  }
};

const getStore = function(preloadedState, history, enhancer) {
  return createStore(
    connectRouter(history)(rootReducer),
    preloadedState,
    enhancer
  );
};

export {
  getStore,
  getMiddlewares,
  getEnhancer,
};
