const HTTP_NO_CONTENT = 204;

const ENVIRONMENTS = {
  'DEVELOPMENT': `development`,
  'STAGING': `staging`,
  'PRODUCTION': `production`,
};

const REFERRAL_MEDIUMS = {
  review: `First Round Review`,
  firstround: `First Round Dot Com`,
  personal: `Personal Referral`,
};

export {
  HTTP_NO_CONTENT,
  ENVIRONMENTS,
  REFERRAL_MEDIUMS,
};
