import { fetchSearch } from 'helpers/algoliasearch';
import { getRequest, postRequest, patchRequest, deleteRequest } from '../helpers/api';
import { push } from 'connected-react-router';

import uuid from 'uuid';

function toggleAdminMode() {
  return {
    type: `TOGGLE_ADMIN_MODE`,
  };
}

function search(request) {
  return dispatch => {
    dispatch(searchRequested(request));
    return fetchSearch(request)
      .then(response => {
        dispatch(searchSucceeded(request, response));
        return response;
      })
      .catch(() => {
        dispatch(push(`/internal-server-error`));
      });
  };
}

function searchRequested(request) {
  return {
    type: `SEARCH_REQUESTED`,
    request: request,
  };
}

function searchSucceeded(request, response) {
  return {
    type: `SEARCH_SUCCEEDED`,
    request: request,
    response: response,
  };
}

function setSearchQuery(query, namespace) {
  return {
    type: `SET_SEARCH_QUERY`,
    query,
    namespace,
  };
}

function setSearchQueryInput(query, namespace) {
  return {
    type: `SET_SEARCH_QUERY_INPUT`,
    query,
    namespace,
  };
}

function setSearchPage(page, namespace) {
  return {
    type: `SET_SEARCH_PAGE`,
    page,
    namespace,
  };
}

function selectSearchFilter(filter, namespace) {
  return {
    type: `SELECT_SEARCH_FILTER`,
    filter,
    namespace,
  };
}

function deselectSearchFilter(filter, namespace) {
  return {
    type: `DESELECT_SEARCH_FILTER`,
    filter,
    namespace,
  };
}

function track(request) {
  return {
    type: `TRACK`,
    request,
  };
}

const getAdminStatsSuccess = (response) => ({
  type: `GET_ADMIN_STATS_SUCCESS`,
  response,
});

const getAdminStats = () => dispatch => {
  getRequest(`/admin/stats`).then((response) => {
    dispatch(getAdminStatsSuccess(response));
  });
};

function logIn(request) {
  return (dispatch) => {
    dispatch(logInRequest(request));

    return postRequest(`/users/sign_in`, request)
      .then(response => {
        dispatch(logInSuccess(request, response));
        dispatch(userReceived(response));
        if (response.hiringManager) {
          dispatch(push(request.redirectPath || `/hire`));
        } else {
          dispatch(push(`/job-board`));
        }
      })
      .catch(() => {
        dispatch(createMessage({
          body: `We could not find a user tied to your LinkedIn account. Please go through the signup process.`,
          dismissable: true,
          type: `error`,
          expires: false,
        }));
      });
  };
}

function logInRequest(request) {
  return {
    type: `LOG_IN_REQUEST`,
    request,
  };
}

function logInSuccess(request, response) {
  return {
    type: `LOG_IN_SUCCESS`,
    request,
    response,
  };
}


function userReceived(response) {
  return {
    type: `USER_RECEIVED`,
    response,
  };
}

function submitPassword(request) {
  return () => {
    return postRequest(`/entries`, request)
      .catch(error => {
        throw error;
      });
  };
}

function signUp(request) {
  return dispatch => {
    dispatch(signUpRequest(request));

    return postRequest(`/users`, request)
      .then(response => {
        dispatch(signUpSuccess(request, response));
        return dispatch(logIn(request));
      })
      .catch(error => {
        if (error.status === 409) {
          return dispatch(logIn(request));
        } else {
          dispatch(createMessage({
            body: error.body.error,
            dismissable: true,
            type: `error`,
            expires: false,
          }));
          dispatch(push(`/`));
          return dispatch(signUpFailure(request, error));
        }
      });
  };
}

function signUpRequest(request) {
  return {
    type: `SIGN_UP_REQUEST`,
    request,
  };
}

function signUpSuccess(request, response) {
  return {
    type: `SIGN_UP_SUCCESS`,
    request,
    response,
  };
}

function signUpFailure(request, error) {
  return {
    type: `SIGN_UP_FAILURE`,
    request,
    error,
  };
}

function submitUserInfo(request) {
  return dispatch => {
    return patchRequest(`/users/${request.user.id}`, request)
      .then(response => {
        dispatch(userReceived(response));
        return response;
      });
  };
}

const newTalentEngagement = (request) => (dispatch) => {
  return postRequest(request.url, request)
    .then(response => {
      dispatch(getResourceSuccess(request, response));
      dispatch(createMessage({
        body: `Created a new talent engagement for this candidate.`,
        dismissable: true,
        type: `success`,
        expires: true,
      }));
      return response;
    }).catch(() => {
      dispatch(createMessage({
        body: `Could not create a new talent engagement for this candidate.`,
        dismissable: true,
        type: `error`,
        expires: true,
      }));
    });
};

function getResource(request) {
  return dispatch => {
    return getRequest(request.url)
      .then(response => {
        dispatch(getResourceSuccess(request, response));
        return response;
      });
  };
}

function getResourceSuccess(request, response) {
  return {
    type: `GET_RESOURCE_SUCCESS`,
    request,
    response,
  };
}

function getResources(request) {
  return dispatch => {
    return getRequest(request.url)
      .then(response => {
        dispatch(getResourcesSuccess(request, response));
        return response;
      });
  };
}

function getResourcesSuccess(request, response) {
  return {
    type: `GET_RESOURCES_SUCCESS`,
    request,
    response,
  };
}

function updateResource(request) {
  return dispatch => {
    return patchRequest(request.url, request)
      .then(response => {
        dispatch(updateResourceSuccess(request, response));
        return response;
      })
      .catch(error => {
        // TODO: consider placing this in Redux middleware.
        if (error.body.display_message) {
          dispatch(createMessage({
            body: error.body.display_message,
            dismissable: true,
            type: `error`,
            expires: false,
          }));
        }
      });
  };
}

function updateResourceSuccess(request, response) {
  return {
    type: `UPDATE_RESOURCE_SUCCESS`,
    request,
    response,
  };
}

function deleteResource(request) {
  return dispatch => {
    return deleteRequest(request.url, request)
      .then(response => {
        dispatch(deleteResourceSuccess(request, response));
        return response;
      });
  };
}

function deleteResourceSuccess(request, response) {
  return {
    type: `DELETE_RESOURCE_SUCCESS`,
    request,
    response,
  };
}

function createResource(request) {
  return dispatch => {
    return postRequest(request.url, request)
      .then(response => {
        dispatch(createResourceSuccess(request, response));
        return response;
      });
  };
}

function createResourceSuccess(request, response) {
  return {
    type: `CREATE_RESOURCE_SUCCESS`,
    request,
    response,
  };
}

function clear(clearType, namespace) {
  return {
    type: `CLEAR`,
    clearType,
    namespace,
  };
}

function clearSearch(searchType) {
  return {
    type: `CLEAR_SEARCH`,
    searchType,
  };
}

function createMessage(payload) {
  return dispatch => {
    const id = uuid.v4();
    const message = {...payload, ...{id}};
    dispatch(createMessageSuccess(message));

    if (message.expires) {
      setTimeout(() => dispatch(clearMessage(message)), 5000);
    }
  };
}

function createMessageSuccess(message) {
  return {
    type: `CREATE_MESSAGE_SUCCESS`,
    message,
  };
}

function clearMessage(message) {
  return {
    type: `CLEAR_MESSAGE`,
    message,
  };
}

function clearMessages(message) {
  return {
    type: `CLEAR_MESSAGES`,
    message,
  };
}

function sendMagicLink(email) {
  return dispatch => {
    return postRequest(`/users/send_magic_link`, { email })
      .then(response => {
        dispatch(sendMagicLinkSuccess(response));
        return response;
      })
      .catch(error => {
        dispatch(sendMagicLinkFailure(error));
        throw error;
      });
  };
}

function sendMagicLinkSuccess(response) {
  return {
    type: `SEND_MAGIC_LINK_SUCCESS`,
    response,
  };
}

function sendMagicLinkFailure(error) {
  return {
    type: `SEND_MAGIC_LINK_FAILURE`,
    error,
  };
}

function trackCandidateEvent(eventType, page, userId, button=null) {
  postRequest(`/tracked_candidate_events`, { event_type: eventType, page, user_id: userId, button })
      .then(() => {
        console.log(`${eventType} event tracked successfully`);
      })
      .catch(error => {
        console.error(`Error tracking ${eventType} event:`, error);
      });
  };

export {
  toggleAdminMode,
  search,
  searchRequested,
  setSearchQuery,
  setSearchQueryInput,
  setSearchPage,
  selectSearchFilter,
  deselectSearchFilter,
  getResources,
  signUp,
  logIn,
  submitUserInfo,
  userReceived,
  getAdminStats,
  createResource,
  getResource,
  updateResource,
  deleteResource,
  newTalentEngagement,
  clear,
  clearSearch,
  createMessage,
  clearMessage,
  clearMessages,
  track,
  submitPassword,
  sendMagicLink,
  sendMagicLinkFailure,
  sendMagicLinkSuccess,
  trackCandidateEvent,
};
