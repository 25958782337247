import React from 'react';
import PropTypes from 'prop-types';

import {
  OverlayTrigger,
  Tooltip,
  Glyphicon,
} from 'react-bootstrap';

import 'stylesheets/application/featured-candidate-star.less';

class FeaturedCandidateStar extends React.Component {
  render() {
    if (!this.props.featured) {
      return null;
    }

    const FeaturedTooltip = (
      <Tooltip id="tooltip" className='featured-candidate-star-tooltip'>
        This candidate is featured and working directly with the First Round talent team.
      </Tooltip>
    );

    if (!this.props.featured) {
      return null;
    }

    return (
      <OverlayTrigger placement="top" overlay={FeaturedTooltip}>
        <div className='featured-candidate-star'>
          <Glyphicon glyph='star' />
        </div>
      </OverlayTrigger>
    );
  }
}

FeaturedCandidateStar.propTypes = {
  featured: PropTypes.bool,
};

export default FeaturedCandidateStar;
