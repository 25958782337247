import React from 'react';
import PropTypes from 'prop-types';
import { Button, Glyphicon } from 'react-bootstrap';

import 'stylesheets/application/paginator.less';

/**
 * Paginator expects zero-indexed inputs. When on the first page,
 * this.props.currentPage should be zero.
 *
 * The paginator will take care of translating the zero-indexed
 * input into a human readable, one-indexed paginator.
 *
 */
class Paginator extends React.Component {
  render() {
    const firstDisabled = this.props.currentPage === 0;
    const previousDisabled = this.props.currentPage === 0;

    // Subtract one to account for zero-indexed inputs. If there are 3 pages
    // in total, then the zero-indexed page values are 0, 1, 2, with page index
    // 2 as the last page.
    const nextDisabled = this.props.currentPage === this.props.totalPages - 1;
    const lastDisabled = this.props.currentPage === this.props.totalPages - 1;

    const currentPageDisplay = this.props.currentPage + 1;

    return (
      <div className='paginator'>
        <div className='paginator-contents'>
          <Button
            bsSize='large'
            bsStyle='link'
            onClick={this.props.onClickFirst}
            disabled={firstDisabled}
          >
            <Glyphicon
              className='icon'
              glyph='step-backward'
            />
          </Button>
          <Button
            bsSize='large'
            bsStyle='link'
            onClick={this.props.onClickPrevious}
            disabled={previousDisabled}
          >
            <Glyphicon
              className='icon'
              glyph='chevron-left'
            />
          </Button>
          <p className='text'>{currentPageDisplay} of {this.props.totalPages}</p>
          <Button
            bsSize='large'
            bsStyle='link'
            onClick={this.props.onClickNext}
            disabled={nextDisabled}
          >
            <Glyphicon
              className='icon'
              glyph='chevron-right'
            />
          </Button>
          <Button
            bsSize='large'
            bsStyle='link'
            onClick={this.props.onClickLast}
            disabled={lastDisabled}
          >
            <Glyphicon
              className='icon'
              glyph='step-forward'
            />
          </Button>
        </div>
      </div>
    );
  }
}

Paginator.propTypes = {
  currentPage: PropTypes.number.isRequired,
  totalPages: PropTypes.number.isRequired,
  onClickFirst: PropTypes.func.isRequired,
  onClickPrevious: PropTypes.func.isRequired,
  onClickNext: PropTypes.func.isRequired,
  onClickLast: PropTypes.func.isRequired,
};

export default Paginator;
