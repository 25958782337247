import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import { omit, mapValues } from 'lodash';
import { connectRouter } from 'connected-react-router';

import { recordListToHash } from 'helpers/application';

/**
 * Turns an object whose values are different reducer functions into
 * a single reducer function. Any reducer passed in will ignore all
 * actions where `action.namespace` does not match the `namespace`
 * provided.
 *
 * This is a helper function for nesting re-usable reducers within
 * certain modules of the app, like a page. This pattern operates
 * like an enhanced version of combineReducers.
 */
function namespaceReducerCreator(namespace, reducers) {
  return function(state = {}, action) {
    return mapValues(reducers, (reducer, key) => {
      const previousState = state[key];
      const nextState = reducer(previousState, action);

      // Initialize this reducer with the default value
      if (previousState === undefined) {
        return nextState;

      // Ignore subsequent actions that do not match the namespace
      } else if (action.namespace !== namespace) {
        return previousState;

      // Handle actions that do specify a matching namespace
      } else {
        return nextState;
      }
    });
  };
}

function resourceReducerCreator(storeName) {
  return function(state = {}, action) {
    if (
      !action.request ||
      !action.request.store ||
      action.request.store !== storeName
    ) {
      return state;
    }

    switch (action.type) {
      case `GET_RESOURCES_SUCCESS`:
        return {
          ...state,
          ...recordListToHash(action.response, `id`),
        };
      case `GET_RESOURCE_SUCCESS`:
      case `CREATE_RESOURCE_SUCCESS`:
      case `UPDATE_RESOURCE_SUCCESS`: {
        const id = action.response.id;
        return {
          ...state,
          [id]: {
            ...action.response,
          },
        };
      }
      case `DELETE_RESOURCE_SUCCESS`:
        return omit(state, action.request.id);
      default:
        return state;
    }
  };
}

function config(state = {}) {
  return state;
}

function meta(state = {}) {
  return state;
}

function user(state = null, action) {
  switch (action.type) {
    case `USER_RECEIVED`:
      return action.response;
    default:
      return state;
  }
}

function adminMode(state = false, action) {
  switch(action.type) {
    case `TOGGLE_ADMIN_MODE`:
      return !state;
    default:
      return state;
  }
}

function adminStats(state = null, action) {
  switch (action.type) {
    case `GET_ADMIN_STATS_SUCCESS`:
      return action.response;
    default:
      return state;
  }
}

function searches(state = {}, action) {
  switch(action.type) {
    case `SEARCH_SUCCEEDED`:
      return {
        ...state,
        [action.request.searchType]: action.response,
      };
    case `CLEAR_SEARCH`:
      return omit(state, action.searchType);
    default:
      return state;
  }
}

function searchFilters(state = [], action) {
  switch(action.type) {
    case `SELECT_SEARCH_FILTER`:
      return [
        ...state,
        action.filter,
      ];
    case `DESELECT_SEARCH_FILTER`:
      return state.filter(filter => {
        return !(filter.field === action.filter.field && filter.value === action.filter.value);
      });
    case `CLEAR`:
      return action.clearType === `SEARCH_FILTERS` ? [] : state;
    default:
      return state;
  }
}

function searchQuery(state = ``, action) {
  switch(action.type) {
    case `SET_SEARCH_QUERY`:
      return action.query;
    case `CLEAR`:
      return action.clearType === `SEARCH_QUERY` ? `` : state;
    default:
      return state;
  }
}

function searchQueryInput(state = ``, action) {
  switch(action.type) {
    case `SET_SEARCH_QUERY_INPUT`:
      return action.query;
    case `CLEAR`:
      return action.clearType === `SEARCH_QUERY_INPUT` ? `` : state;
    default:
      return state;
  }
}

function messages(state = [], action) {
  switch (action.type) {
    case `CREATE_MESSAGE_SUCCESS`:
      return [...state, action.message];
    case `CLEAR_MESSAGE`:
      return state.filter(message => message.id !== action.message.id);
    case `CLEAR_MESSAGES`:
      return [];
    default:
      return state;
  }
}

function searchCurrentPage(state = 0, action) {
  switch(action.type) {
    case `SET_SEARCH_PAGE`:
      return action.page;
    case `SELECT_SEARCH_FILTER`:
    case `DESELECT_SEARCH_FILTER`:
    case `SET_SEARCH_QUERY`:
      return 0;
    case `CLEAR`:
      return action.clearType === `SEARCH_PAGE` ? 0 : state;
    default:
      return state;
  }
}

const candidateViews = resourceReducerCreator(`candidateViews`);
const introRequests = resourceReducerCreator(`introRequests`);
const userSearchFilters = resourceReducerCreator(`userSearchFilters`);
const users = resourceReducerCreator(`users`);
const talentIntros = resourceReducerCreator(`talentIntros`);

const usersPage = namespaceReducerCreator(
  `usersPage`,
  {
    searchFilters,
    searchQueryInput,
    searchQuery,
    searchCurrentPage,
  }
);

const candidatesPage = namespaceReducerCreator(
  `candidatesPage`,
  {
    searchFilters,
    searchQueryInput,
    searchQuery,
    searchCurrentPage,
  }
);

const rootReducer = combineReducers({
  config,
  adminMode,
  adminStats,
  meta,
  user,
  searches,
  introRequests,
  talentIntros,
  candidateViews,
  users,
  userSearchFilters,
  usersPage,
  candidatesPage,
  messages,
  form: formReducer,
});

export {
  rootReducer,
  namespaceReducerCreator,
  resourceReducerCreator,
  config,
  meta,
  user,
  searches,
  searchFilters,
  searchQuery,
  searchQueryInput,
  messages,
  searchCurrentPage,
};
