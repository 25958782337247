import React from 'react';
import PropTypes from 'prop-types';
import { compose, withState } from 'recompose';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { withStyles } from '@material-ui/core/styles';

import Button from 'components/Button';
import Modal from 'components/Modal';
import EditCandidateForm from 'components/EditCandidateForm';
import TalentIntrosForCandidate from 'components/TalentIntrosForCandidate';

import 'stylesheets/application/admin-candidate-modal.less';

const styles = {
  root: {
    'font-size': 12,
    'flex-grow': 1,
  },
};

const AdminCandidateModal = props => {
  const {
    candidate,
    isOpen,
    setIsOpen,
    tab,
    setTab,
    classes,
  } = props;

  return (
    <div className='admin-candidate-modal'>
      <Button onClick={() => setIsOpen(true)}>
        Edit
      </Button>
      <Modal
        onClose={() => setIsOpen(false)}
        open={isOpen}
        wide={true}
      >
        <div className='admin-candidate-modal-contents'>
          <Tabs
            value={tab}
            indicatorColor="primary"
            textColor="primary"
            onChange={(e, tab) => setTab(tab)}
            centered
          >
            <Tab label="Edit" value="edit" className={classes.root} />
            <Tab label="Activity" value="history" className={classes.root} />
          </Tabs>
          {tab === `edit` && (
            <EditCandidateForm
              resourceName='user'
              resourceStoreKey='users'
              resourceId={candidate.objectID}
              admin
            />
          )}
          {tab === `history` && (
            <div className="history">
              <TalentIntrosForCandidate candidate={candidate} />
            </div>
          )}
        </div>
      </Modal>
    </div>
  );
};

AdminCandidateModal.propTypes = {
  classes: PropTypes.object.isRequired,
  setIsOpen: PropTypes.func.isRequired,
  setTab: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  candidate: PropTypes.object.isRequired,
  tab: PropTypes.oneOf([`history`, `edit`]).isRequired,
};

export default compose(
  withState(`isOpen`, `setIsOpen`, false),
  withState(`tab`, `setTab`, `edit`),
  withStyles(styles)
)(AdminCandidateModal);
