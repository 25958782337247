import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import MultiSelectReduxField from 'components/MultiSelectReduxField';

const styles = {
  label: {
    'font-size': 18,
  },
  listItemText: {
    'font-size': 18,
  },
  helpText: {
    'font-size': 12,
  },
  textField: {
    'margin-bottom': 15,
  },
};

class StyledMultiSelectReduxField extends React.Component {
  render() {
    const { classes, optionObjects, ...props } = this.props;

    return (
      <MultiSelectReduxField
        items={optionObjects.map(option => {
          return {value: option.id, label: option.name};
        })}
        fullWidth={true}
        className={classes.textField}
        InputLabelProps={{className: classes.label}}
        InputProps={{className: classes.label}}
        FormHelperTextProps={{className: classes.helpText}}
        ListItemTextProps={{classes: {
          primary: classes.listItemText,
        }}}
        {...props}
      />
    );
  }
}

StyledMultiSelectReduxField.propTypes = {
  classes: PropTypes.object.isRequired,
  name: PropTypes.string,
  label: PropTypes.string,
  helperText: PropTypes.string,
  optionObjects: PropTypes.array,
  validate: PropTypes.array,
};

export default withStyles(styles)(StyledMultiSelectReduxField);
