import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { search } from 'actions/actions';

import WithUser from 'components/WithUser';

function withSearch(WrappedComponent) {
  class WrappedComponentWithSearch extends React.Component {
    constructor(props) {
      super(props);
      this.search = this.search.bind(this);
    }

    searchDefaults(searchRequest) {
      const apiKey = this.props.user.algoliaSearchApiKeys[searchRequest.userType][searchRequest.model.toLowerCase()];

      return {
        env: this.props.config.env,
        algoliaAppId: this.props.config.algoliaAppId,
        algoliaSearchApiKey: apiKey,
      };
    }

    search(searchRequest) {
      const searchRequestWithDefaults = {
        ...this.searchDefaults(searchRequest),
        ...searchRequest,
      };

      return this.props.search(searchRequestWithDefaults);
    }

    render() {
      return <WrappedComponent
        {...this.props}
        search={this.search}
      />;
    }
  }

  WrappedComponentWithSearch.propTypes = {
    search: PropTypes.func.isRequired,
    config: PropTypes.object.isRequired,
    user: PropTypes.object.isRequired,
  };

  function mapStateToProps(state) {
    return {
      config: state.config,
    };
  }

  function mapDispatchToProps(dispatch) {
    return {
      search: (searchRequest) => dispatch(search(searchRequest)),
    };
  }

  return WithUser(connect(
    mapStateToProps,
    mapDispatchToProps
  )(WrappedComponentWithSearch));
}

export default withSearch;
