import React from 'react';
import PropTypes from 'prop-types';
import MaterialPopover from '@material-ui/core/Popover';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  popover: {
    pointerEvents: `none`,
  },
  paper: {
    padding: theme.spacing.unit,
  },
});

class Popover extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      anchorEl: null,
    };

    this.handlePopoverOpen = this.handlePopoverOpen.bind(this);
    this.handlePopoverClose = this.handlePopoverClose.bind(this);
  }

  handlePopoverOpen(event) {
    this.setState({ anchorEl: event.currentTarget });
  }

  handlePopoverClose() {
    this.setState({ anchorEl: null });
  }

  render() {
    const { classes } = this.props;
    const { anchorEl } = this.state;
    const open = Boolean(anchorEl);

    if (this.props.disablePopover) {
      return this.props.parent;
    }

    return (
      <div>
        <div
          aria-owns={open ? `mouse-over-popover` : undefined}
          aria-haspopup='true'
          onMouseEnter={this.handlePopoverOpen}
          onMouseLeave={this.handlePopoverClose}
        >
          {this.props.parent}
        </div>
        <MaterialPopover
          id='mouse-over-popover'
          className={classes.popover}
          classes={{
            paper: classes.paper,
          }}
          open={open}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: `bottom`,
            horizontal: `center`,
          }}
          transformOrigin={{
            vertical: `top`,
            horizontal: `center`,
          }}
          onClose={this.handlePopoverClose}
          disableRestoreFocus
        >
          {this.props.popover}
        </MaterialPopover>
      </div>
    );
  }
}

Popover.propTypes = {
  classes: PropTypes.object.isRequired,
  parent: PropTypes.any,
  popover: PropTypes.any,
  disablePopover: PropTypes.bool,
};

export default withStyles(styles)(Popover);
