import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import WithUser from 'components/WithUser';
import ConditionalRender from 'components/ConditionalRender';

class RequireAdmin extends React.Component {
  render() {
    const userIsAdmin = !!this.props.user && this.props.user.admin;
    const adminModePasses = !(this.props.adminModeOnly && !this.props.adminMode);

    return (
      <ConditionalRender
        shouldRender={userIsAdmin && adminModePasses}
      >
        {this.props.children}
      </ConditionalRender>
    );
  }
}

RequireAdmin.propTypes = {
  user: PropTypes.object,
  adminModeOnly: PropTypes.bool,
  adminMode: PropTypes.bool.isRequired,
  children: PropTypes.any.isRequired,
};

function mapStateToProps(state) {
  return {
    adminMode: state.adminMode,
  };
}

export default WithUser(connect(mapStateToProps)(RequireAdmin));
