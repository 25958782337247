import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  getSearchFilter,
  getSearchQuery,
  getSearchCurrentPage,
} from 'selectors/selectors';
import { clear } from 'actions/actions';

import Button from 'components/Button';
import WithSearch from 'components/WithSearch';

import 'stylesheets/application/search-results-list.less';

class SearchResultsList extends React.Component {
  constructor(props) {
    super(props);
    this.clearSearch = this.clearSearch.bind(this);
  }

  componentDidMount() {
    this.SearchResultsList(this.props);
  }

  componentDidUpdate() {
    window.scrollTo(0, 0);
  }

  searchRequest(props) {
    /**
     * Transforms: [{ field: `location`, value: `San Fran`}, { field: `location`, value: `LA`}]
     * into:       { location: [`San Fran`, `LA`] }
     */
    const searchFiltersByField = props.searchFilters.reduce((searchFiltersByField, filter) => {
      if (searchFiltersByField[filter.field]) {
        searchFiltersByField[filter.field].push(filter.value);
      } else {
        searchFiltersByField[filter.field] = [filter.value];
      }

      return searchFiltersByField;
    }, {});

    const searchFilters = Object.keys(searchFiltersByField).map(field => {
      return {
        name: field,
        values: searchFiltersByField[field],
        operator: `OR`,
      };
    });

    const parentFilters = props.parentFilters ? props.parentFilters : [];

    const filters = [...searchFilters, ...parentFilters];

    return {
      params: {
        filters: filters,
        page: props.searchCurrentPage,
      },
      query: props.searchQuery,
      model: props.model,
      userType: props.userType,
      searchType: props.searchType,
    };
  }

  SearchResultsList(props) {
    const request = this.searchRequest(props);
    this.props.search(request);
  }

  componentWillReceiveProps(nextProps) {
    // TODO: see if this can be pushed into the WithSearch wrapper component.
    const searchRequest = this.searchRequest(this.props);
    const nextSearchRequest = this.searchRequest(nextProps);

    const searchRequestChanged = JSON.stringify(searchRequest) !== JSON.stringify(nextSearchRequest);

    if (searchRequestChanged) {
      this.SearchResultsList(nextProps);
    }
  }

  clearSearch() {
    this.props.clear(`SEARCH_FILTERS`);
    this.props.clear(`SEARCH_QUERY`);
    this.props.clear(`SEARCH_QUERY_INPUT`);
  }

  render() {
    const search = this.props.searches[this.props.searchType];

    if (!search) {
      return null;
    }

    return (
      <div className='search-results-list'>
        {!!search.nbHits &&
          <div className='search-results-count'>
            {search.nbHits} Matching {this.props.countType}
          </div>
        }
        {(search.nbHits === 0 && !this.props.hideClearFiltersButton) &&
          <div className='no-results-warning'>
            <h3>No {this.props.countType} matched your current preferences.</h3>
            <Button
              displayType='primary'
              onClick={this.clearSearch}
            >
              Clear Filters
            </Button>
          </div>
        }
        <div className={this.props.twoColumnList ? `two-column-list` : ``}>
          {search.hits.map(result => this.props.resultComponentCreator(result))}
        </div>
      </div>
    );
  }
}

SearchResultsList.propTypes = {
  countType: PropTypes.string.isRequired,
  searches: PropTypes.object.isRequired,
  searchQuery: PropTypes.string,
  searchType: PropTypes.string.isRequired,
  model: PropTypes.string.isRequired,
  userType: PropTypes.string.isRequired,
  searchFilters: PropTypes.array.isRequired,
  search: PropTypes.func.isRequired,
  parentFilters: PropTypes.array,
  hideClearFiltersButton: PropTypes.bool,
  resultComponentCreator: PropTypes.any.isRequired,
  searchCurrentPage: PropTypes.number.isRequired,
  clear: PropTypes.func.isRequired,
  twoColumnList: PropTypes.bool,
};

function mapStateToProps(state, ownProps) {
  return {
    searches: state.searches,
    searchFilters: getSearchFilter(state, ownProps),
    searchQuery: getSearchQuery(state, ownProps),
    searchCurrentPage: getSearchCurrentPage(state, ownProps),
  };
}

function mapDispatchToProps(dispatch, ownProps) {
  return {
    clear: clearType => dispatch(clear(clearType, ownProps.namespace)),
  };
}

const SearchResultsListWithState = connect(
  mapStateToProps,
  mapDispatchToProps
)(SearchResultsList);

export default WithSearch(SearchResultsListWithState);
