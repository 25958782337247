import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Col } from 'react-bootstrap';

import WithResourceActions from 'components/WithResourceActions';
import Button from 'components/Button';

import 'stylesheets/application/admin-manage-candidate-buttons.less';

class AdminManageCandidateButtons extends React.Component {
  componentDidMount() {
    this.loadUser();
  }

  handleUpdateUser(valueObject) {
    const userId = this.props.userObject.id;
    const request = {
      url: `/admin/users/${userId}`,
      store: `users`,
      user: {
        id: userId,
        ...valueObject,
      },
    };
    this.props.updateResource(request);
    this.props.onCancel();
  }

  loadUser() {
    const request = {
      url: `/admin/users/${this.props.userId}`,
      store: `users`,
    };
    this.props.getResource(request);
  }

  getApproveButtonHtml() {
    return (
      <Button
        key='approve'
        displayType='primary'
        onClick={() => this.handleUpdateUser({status: `approved`})}
      >
        Send to Network List
      </Button>
    );
  }

  getDenyButtonHtml() {
    return (
      <Button
        key='deny'
        displayType='warning'
        onClick={() => this.handleUpdateUser({status: `denied`})}
      >
        Pass
      </Button>
    );
  }

  getRemoveButtonHtml() {
    return (
      <Button
        key='remove'
        displayType='warning'
        onClick={() => this.handleUpdateUser({status: `removed`})}
      >
        Remove from List
      </Button>
    );
  }

  getIntroductoryConversationButtonHtml() {
    return (
      <Button
        key='introductory-conversation'
        displayType='primary'
        onClick={() => this.handleUpdateUser({status: `introductory_conversation`})}
      >
        Introductory Conversation
      </Button>
    );
  }

  getHiredButtonHtml() {
    return (
      <Button
        key='hired'
        displayType='primary'
        onClick={() => this.handleUpdateUser({status: `hired_by_community`})}
      >
        Hired!
      </Button>
    );
  }

  getNoLongerAvailableButtonHtml() {
    return (
      <Button
        key='no-longer-available'
        displayType='warning'
        onClick={() => this.handleUpdateUser({status: `no_longer_available`})}
      >
        No Longer Available
      </Button>
    );
  }

  getCandidateDeclinedButtonHtml() {
    return (
      <Button
        key='candidate-declined'
        displayType='warning'
        onClick={() => this.handleUpdateUser({status: `candidate_declined`})}
      >
        Candidate Declined
      </Button>
    );
  }

  getInactivityButtonHtml() {
    return (
      <Button
        key='inactivity'
        displayType='warning'
        onClick={() => this.handleUpdateUser({status: `inactive`})}
      >
        Inactive
      </Button>
    );
  }

  getCancelButtonHtml() {
    return (
      <Button
        key='cancel'
        onClick={this.props.onCancel}
      >
        Cancel
      </Button>
    );
  }

  getTriageButtonsHtml() {
    const buttons = [
      {
        shouldRender: this.props.userObject.status === `pending`,
        render: this.getIntroductoryConversationButtonHtml,
      },
      {
        shouldRender: (
          this.props.userObject.sharingAllowed && (
            this.props.userObject.status === `pending` ||
            this.props.userObject.status === `introductory_conversation`
          )
        ),
        render: this.getApproveButtonHtml,
      },
      {
        shouldRender: this.props.userObject.status === `approved`,
        render: this.getHiredButtonHtml,
      },
      {
        shouldRender: this.props.userObject.status === `pending`,
        render: this.getDenyButtonHtml,
      },
      {
        shouldRender: this.props.userObject.status === `introductory_conversation`,
        render: this.getCandidateDeclinedButtonHtml,
      },
      {
        shouldRender: this.props.userObject.status === `approved`,
        render: this.getNoLongerAvailableButtonHtml,
      },
      {
        shouldRender: !this.props.userObject.sharingAllowed && this.props.userObject.status === `approved`,
        render: this.getInactivityButtonHtml,
      },
      {
        shouldRender: this.props.userObject.sharingAllowed && this.props.userObject.status === `approved`,
        render: this.getRemoveButtonHtml,
      },
      {
        shouldRender: true,
        render: this.getCancelButtonHtml,
      },
    ];

    return buttons.
      filter(button => button.shouldRender).
      map(button => button.render.apply(this));
  }

  render() {
    if (!this.props.userObject) {
      return null;
    }

    const triageButtonsHtml = this.getTriageButtonsHtml();

    return (
      <Col className='admin-manage-candidate-buttons'>
        {triageButtonsHtml}
      </Col>
    );
  }
}

AdminManageCandidateButtons.propTypes = {
  userId: PropTypes.string.isRequired,
  userObject: PropTypes.object,
  getResource: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  updateResource: PropTypes.func.isRequired,
};

function mapStateToProps(state, ownProps) {
  return {
    userObject: state.users[ownProps.userId],
  };
}

const AdminManageCandidateButtonsWithState = connect(
  mapStateToProps
)(AdminManageCandidateButtons);

export default WithResourceActions(AdminManageCandidateButtonsWithState);
