import React from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';

import WithUser from 'components/WithUser';

class CandidateRoute extends React.Component {

  isCandidate() {
    if (!this.props.user) {
      return false;
    }

    return this.props.user.candidate || this.props.user.admin;
  }

  component() {
    return this.props.component;
  }


  render() {
    if (!this.isCandidate()) {
      return <Redirect to='/welcome/' />;
    }

    return (
      <Route {...{...this.props, component: this.component()}}></Route>
    );
  }
}

CandidateRoute.propTypes = {
  component: PropTypes.func,
  path: PropTypes.string,
  user: PropTypes.object,
};

export default WithUser(CandidateRoute);
