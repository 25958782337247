import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose, withState, withProps } from 'recompose';
import { Field, reduxForm, formValueSelector } from 'redux-form';
import { Checkbox } from 'redux-form-material-ui';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';

import {
  gitHubUrlValidator,
  googleUrlValidator,
  limitSpecialCharactersValidator,
  linkedInFormValidator,
  requiredFormValidator,
  websiteValidator,
} from 'helpers/application';
import { regionsForCandidates } from '../selectors/selectors';
import { deleteResource, newTalentEngagement, createMessage } from '../actions/actions';

import FileUploadField from 'components/FileUploadField';
import WithResource from 'components/WithResource';
import StyledTextField from 'components/StyledTextField';
import StyledMultiSelectReduxField from 'components/StyledMultiSelectReduxField';
import LinkedGlyphiconWithTooltip from 'components/LinkedGlyphiconWithTooltip';

import 'stylesheets/application/edit-candidate-form.less';

const styles = {
  label: {
    'font-size': 18,
  },
  listItemText: {
    'font-size': 18,
  },
  helpText: {
    'font-size': 12,
  },
  shareText: {
    'font-size': 16,
    'line-height': 1.2,
  },
  textField: {
    'margin-bottom': 15,
  },
  button: {
    'font-size': 18,
    textTransform: `none`,
  },
  form: {
    'margin-bottom': 20,
  },
  manageButtonsContainer: {
    display: `flex`,
    justifyContent: `space-between`,
  },
};

class EditCandidateForm extends React.Component {

  componentDidUpdate() {
    // This will trigger a validation warning if the admin moves the candidate
    // to intro conversation while the company name is blank.
    this.props.touch(`companyName`);
  }

  render() {
    const { classes, invalid, isSaving, candidate, handleNewTalentEngagement, handleDelete } = this.props;
    const disabled = invalid || isSaving;

    const SaveCandidateButton = (
      <Button
        variant="outlined"
        color={disabled ? `default` : `primary`}
        size="large"
        className={classes.button}
        type="submit"
        disabled={disabled || this.props.isSaving}
      >
        Save Candidate
      </Button>
    );

    const onboardingComplete = candidate.onboardingStatus === `complete`;
    const profileValidators = onboardingComplete ? [requiredFormValidator] : [];
    const canApproveCandidate = onboardingComplete && this.props.initialValues.sharingAllowed;
    const allowedStatuses = (canApproveCandidate ?
      this.props.meta.userStatuses :
      this.props.meta.userStatuses.filter(status => status !== `approved`)
    );

    return (
      <div className='edit-candidate-form'>
        <form onSubmit={this.props.handleSubmit} className={classes.form}>
          <div className='section-header'>
            <div>
              <h3>{candidate.firstName} {candidate.lastName}</h3>
              <h5>{candidate.title} at {candidate.companyName}</h5>
              <a href={`/candidates/${candidate.slug}`} target='_blank' rel='noopener noreferrer'>Share Link</a>
            </div>
            {SaveCandidateButton}
          </div>
          <hr/>
          <StyledTextField
            name='status'
            label='Status'
            select={true}
            classes={classes}
            helperText={!canApproveCandidate ? `This candidate cannot be approved until they complete onboarding and allow sharing.` : ``}
            validate={[requiredFormValidator]}
          >
            {allowedStatuses.map((userStatus) => {
              return(
                <MenuItem
                  key={userStatus}
                  value={userStatus}
                  className={classes.label}
                >
                  {this.props.meta.userStatusExplanations[userStatus]}
                </MenuItem>
              );
            })}
          </StyledTextField>
          <StyledTextField
            name='assignedToSalesforceId'
            label='Assigned To'
            classes={classes}
            select={true}
          >
            {this.props.meta.assignedToOptions.map((assignedToOption) => {
              return(
                <MenuItem
                  key={assignedToOption.salesforce_id}
                  value={assignedToOption.salesforce_id}
                  className={classes.label}
                >
                  {assignedToOption.display_name}
                </MenuItem>
              );
            })}
          </StyledTextField>
          <StyledTextField
            name='publicNote'
            label='Public Notes'
            variant='outlined'
            rows={5}
            rowsMax={10}
            multiline={true}
            classes={classes}
          />
          <StyledTextField
            name='privateNote'
            label='Private Notes'
            variant='outlined'
            rows={5}
            rowsMax={15}
            multiline={true}
            classes={classes}
          />
          <StyledTextField
            name='experienceLevelId'
            label='Experience Level'
            select={true}
            classes={classes}
          >
            {this.props.meta.experienceLevels.map((experienceLevel) => {
              return(
                <MenuItem
                  key={experienceLevel.id}
                  value={experienceLevel.id}
                  className={classes.label}
                >
                  {experienceLevel.name}
                </MenuItem>
              );
            })}
          </StyledTextField>
          <FormControlLabel
            label="Featured (Working Directly)"
            classes={{label: classes.label}}
            control={
              <Field
                name="featured"
                component={Checkbox}
              />
            }
          />
          <hr/>
          <div className='section-header'>
            <div>
              <h3>Candidate Profile</h3>
              {onboardingComplete && (
                <p>This candidate has completed onboarding. All fields are required.</p>
              )}
            </div>
            {SaveCandidateButton}
          </div>
          <hr/>
          <StyledTextField
            name='firstName'
            label='First name'
            classes={classes}
            helperText='Required'
            validate={[requiredFormValidator, limitSpecialCharactersValidator]}
          />
          <StyledTextField
            name='lastName'
            label='Last name'
            classes={classes}
            helperText='Required'
            validate={[requiredFormValidator, limitSpecialCharactersValidator]}
          />
          <StyledTextField
            name='email'
            label='Email'
            classes={classes}
            helperText='Required'
            validate={[requiredFormValidator]}
          />
          <StyledTextField
            name='companyName'
            label='Company'
            helperText='Required for candidates in introductory conversation'
            classes={classes}
            validate={this.props.formStatus === `introductory_conversation` ? [requiredFormValidator, limitSpecialCharactersValidator] : [limitSpecialCharactersValidator]}
          />
          <StyledTextField
            name='title'
            label='Title'
            helperText={onboardingComplete ? `Required` : ``}
            classes={classes}
            validate={[...profileValidators, limitSpecialCharactersValidator]}
          />
          <StyledMultiSelectReduxField
            name='regionIds'
            label='Location'
            helperText={onboardingComplete ? `Required` : ``}
            optionObjects={this.props.regions}
            validate={profileValidators}
          />
          <StyledTextField
            name='primaryFunctionalAreaId'
            label='Role'
            helperText={onboardingComplete ? `Required` : ``}
            select={true}
            classes={classes}
            validate={profileValidators}
          >
            {this.props.meta.functionalAreas.map((functionalArea) => {
              return (
                <MenuItem
                  key={functionalArea.id}
                  value={functionalArea.id}
                  className={classes.label}
                >
                  {functionalArea.name}
                </MenuItem>
              );
            })}
          </StyledTextField>
          <StyledMultiSelectReduxField
            name='roleTypeIds'
            label='Type of Role'
            helperText='What types of roles are you interested in?'
            optionObjects={this.props.meta.roleTypes}
            validate={profileValidators}
          />
          <StyledMultiSelectReduxField
            name='companySizeBucketIds'
            label='Company Size'
            helperText={onboardingComplete ? `Required` : ``}
            optionObjects={this.props.meta.companySizeBuckets}
            validate={profileValidators}
          />
          <StyledTextField
            name='whenLooking'
            label='Timing'
            helperText={onboardingComplete ? `Required` : ``}
            select={true}
            classes={classes}
            validate={profileValidators}
          >
            {this.props.meta.whenLooking.map((timeframe) => {
              return (
                <MenuItem
                  key={timeframe}
                  value={timeframe}
                  className={classes.label}
                >
                  {timeframe}
                </MenuItem>
              );
            })}
          </StyledTextField>
          <hr/>
          <div className='section-header'>
            <h3>Referral Details</h3>
            {SaveCandidateButton}
          </div>
          <hr/>
          <StyledTextField
            name='referralMedium'
            label='Referral Medium'
            classes={classes}
            disabled
            select={true}
          >
            {this.props.meta.referralMediums.map((referralMedium) => {
              return(
                <MenuItem
                  key={referralMedium}
                  value={referralMedium}
                  className={classes.label}
                >
                  {referralMedium}
                </MenuItem>
              );
            })}
          </StyledTextField>
          <StyledTextField
            name='entryPasswordUsed'
            label='Entry Password'
            disabled
            classes={classes}
          />
          <StyledTextField
            name="referralSource"
            label="How did the candidate hear about us?"
            classes={classes}
            multiline={true}
            inputProps={{ maxLength: 255 }}
            disabled
          />
          <hr/>
          <div className='section-header'>
            <h3>Additional Details</h3>
            {SaveCandidateButton}
          </div>
          <hr/>
          <StyledTextField
            name="desiredSalary"
            label="Desired compensation"
            helperText="(in $)"
            classes={classes}
            inputProps={{
              maxLength: 8,
              type: `number`,
            }}
          />
          <StyledMultiSelectReduxField
            name='skillIds'
            label='Skills'
            helperText='Select all that apply'
            optionObjects={this.props.meta.skills}
          />
          <StyledMultiSelectReduxField
            name='industryIds'
            label='Desired Industries'
            helperText='Select all that apply'
            optionObjects={this.props.meta.industries}
          />
          <FormControlLabel
            name='requiresSponsorship'
            label='Requires visa sponsorship to work in the US'
            classes={classes}
            control={
              <Field
                name='requiresSponsorship'
                component={Checkbox}
              />
            }
            disabled
          />
          <FormControlLabel
            name='permissionForTalentNewsletter'
            label='Talent Newsletter Opt-In'
            classes={classes}
            control={
              <Field
                name='permissionForTalentNewsletter'
                component={Checkbox}
              />
            }
            disabled
          />
          <StyledTextField
            name='salesforceTalentEngagementId'
            label='Salesforce Talent Engagement ID'
            helperText='Ask engineering for assistance if this ID is incorrect.'
            classes={classes}
            value={this.props.candidate.salesforceTalentEngagementId}
            disabled
          />
          <StyledTextField
            name='imageUrl'
            label='Profile Image Link'
            helperText='Paste a URL link to an image for this candidate.'
            classes={classes}
          />
          <StyledTextField
            name='linkedinUrl'
            label='LinkedIn URL'
            classes={classes}
            validators={[requiredFormValidator, linkedInFormValidator]}
          />
          <div className='resume-label-container'>
            <FormLabel
              className={classes.label}
            >
              {candidate.resumeUrl ? `` : `Upload Resume/CV`}
            </FormLabel>
            {candidate.resumeUrl && (
              <LinkedGlyphiconWithTooltip
                url={candidate.resumeUrl}
                glyphiconKey={'download-alt'}
                tooltipText={'Download the candidate\'s resume'}
                isDownloadEnabled
              />
            )}
          </div>
          <Field name='resume' component={FileUploadField} />
          <FormLabel>OR</FormLabel>
          <StyledTextField
            name='secondaryResumeUrl'
            label='Resume Link'
            helperText="Share their Google Docs/Drive resume link"
            classes={classes}
            validate={[googleUrlValidator]}
          />
          <StyledTextField
            name='websiteUrl'
            label='Website URL'
            classes={classes}
            validate={[websiteValidator]}
          />
          <StyledTextField
            name='portfolioUrl'
            label='Portfolio URL'
            classes={classes}
            validate={[websiteValidator]}
          />
          <StyledTextField
            name='githubUrl'
            label='GitHub URL'
            classes={classes}
            validate={[gitHubUrlValidator]}
          />
          <StyledTextField
            name="bio"
            label="Bio"
            classes={classes}
            multiline={true}
            inputProps={{
              style: {
                height: `300px`,
              },
            }}
          />
          <StyledMultiSelectReduxField
            name='functionalAreaIds'
            label='Other Interests'
            helperText={null}
            optionObjects={this.props.meta.functionalAreas}
          />
        </form>
        <div className='section-header'>
          <h3>Manage</h3>
        </div>
        <hr/>
        <div className={classes.manageButtonsContainer}>
          <Button
            variant="outlined"
            color={!candidate.salesforceTalentEngagementId || isSaving ? `default` : `primary`}
            size="large"
            className={classes.button}
            type="submit"
            disabled={!candidate.salesforceTalentEngagementId || isSaving }
            onClick={handleNewTalentEngagement}
          >
            Start New Talent Engagement
          </Button>
          <Button
            variant="outlined"
            color={candidate.claimed || isSaving ? `default` : `primary`}
            size="large"
            className={classes.button}
            type="submit"
            disabled={candidate.claimed || isSaving}
            onClick={handleDelete}
          >
            Delete Candidate
          </Button>
        </div>
        <hr/>
      </div>
    );
  }
}

EditCandidateForm.propTypes = {
  candidate: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired, // redux-form passes this in
  handleDelete: PropTypes.func.isRequired,
  touch : PropTypes.func.isRequired,
  isSaving: PropTypes.bool.isRequired,
  invalid: PropTypes.bool.isRequired,
  formStatus: PropTypes.string,
  meta: PropTypes.object,
  regions: PropTypes.array.isRequired,
  submitting: PropTypes.bool,
  classes: PropTypes.object.isRequired,
  initialValues: PropTypes.object,
  handleNewTalentEngagement: PropTypes.func.isRequired,
};

const editCandidateFormSelector = formValueSelector(`editCandidate`);

const withRedux = connect(
  (state, ownProps) => ({
    meta: state.meta,
    regions: regionsForCandidates(state),
    candidate: ownProps.user,
    initialValues: ownProps.user,
    formStatus: editCandidateFormSelector(state, `status`),
  }),
  dispatch => ({
    deleteResource: payload => dispatch(deleteResource(payload)),
    newTalentEngagement: request => dispatch(newTalentEngagement(request)),
    createMessage: payload => dispatch(createMessage(payload)),
  })
);

const handleSubmit = props => payload => {
  props.setIsSaving(true);
  props.handleSubmit(payload).then(() => props.setIsSaving(false));
};

const handleDelete = props => () => {
  props.setIsSaving(true);
  props.deleteResource({
    url: `/admin/users/${props.candidate.id}`,
    id: props.candidate.id,
  }).then(() => {
    props.createMessage({
      body: `This pre-filled candidate profile was successfully removed.`,
      dismissable: true,
      type: `success`,
      expires: true,
    });
  });
};

const handleNewTalentEngagement = props => () => {
  props.setIsSaving(true);
  props.newTalentEngagement({
    url: `/admin/users/${props.candidate.id}/new_talent_engagement/`,
    store: `users`,
  }).then((response) => {
    props.setIsSaving(false);
    // The form retains state separate from the user record. We need to push the updated
    // SFID into the form values.
    if (response) {
      props.change(`salesforceTalentEngagementId`, response.salesforceTalentEngagementId);
    }
  });
};

export default compose(
  WithResource,
  withState(`isSaving`, `setIsSaving`, false),
  withRedux,
  reduxForm({ form: `editCandidate` }),
  withProps(props => ({
    handleSubmit: handleSubmit(props),
    handleDelete: handleDelete(props),
    handleNewTalentEngagement: handleNewTalentEngagement(props),
  })),
  withStyles(styles)
)(EditCandidateForm);
