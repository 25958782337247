import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { values } from 'lodash';
import moment from 'moment';
import { compose, withState } from 'recompose';

import WithResourceActions from 'components/WithResourceActions';

class TalentIntrosForCandidate extends React.Component {
  componentDidMount() {
    const { setIsLoaded, candidate } = this.props;
    const request = {
      url: `/admin/salesforce_talent_introductions/?candidate_id=${candidate.objectID}`,
      store: `talentIntros`,
    };

    this.props.getResources(request).
      then(() => setIsLoaded(true));
  }

  render() {
    const { isLoaded, intros } = this.props;

    if (!isLoaded) {
      return null;
    }

    return (
      <div>
        <hr/>
        <h3>Introductions</h3>
        <hr/>
        {intros.length === 0 &&
          <div>This candidate has not been previously requested by any hiring managers or introduced to them.</div>
        }
        {intros.length !== 0 && intros.map(intro => {
          return (
            <div key={intro.id}>
              <b>{intro.submittalOutcomeC}</b> ({moment(intro.submittalOutcomeDate).fromNow()}): {intro.hiringManagerName}, {intro.hiringManagerTitle} at {intro.hiringManagerCompany}
              {` `}
              <a
                href={`https://firstround.my.salesforce.com/${intro.sfid}`}
                target='_blank'
                rel='noopener noreferrer'
              >
                (view in Salesforce)
              </a>
            </div>
          );
        })}
      </div>
    );
  }
}

TalentIntrosForCandidate.propTypes = {
  candidate: PropTypes.object.isRequired,
  getResources: PropTypes.func.isRequired,
  setIsLoaded: PropTypes.func.isRequired,
  isLoaded: PropTypes.bool.isRequired,
  intros: PropTypes.array,
};

const withRedux = connect(
  (state, ownProps) => ({
    intros: values(state.talentIntros).filter((intro) => intro.salesforceTalentEngagementId === ownProps.candidate.salesforce_talent_engagement_id),
  })
);

export default compose(
  withRedux,
  withState(`isLoaded`, `setIsLoaded`, false),
  WithResourceActions
)(TalentIntrosForCandidate);
