import React from 'react';
import PropTypes from 'prop-types';

import { Link } from 'react-router-dom';

import 'stylesheets/application/not-found-page.less';
import FirstRoundEngineeringImage from 'images/first_round_engineering.png';
import WithUser from 'components/WithUser';

class NotFoundPage extends React.Component {
  getRedirectLink() {
    if (!this.props.user) {
      return `/welcome`;
    } else if (this.props.user.hiringManager) {
      return `/hire`;
    } else {
      return `/job-board`;
    }
  }

  render() {
    const redirectLink = this.getRedirectLink();
    const loggedOutText = `to sign up or log in First Round Careers and view opportunities at First Round backed companies.`;
    const loggedInText = `to continue viewing opportunities at First Round backed companies.`;
    const redirectText = this.props.user ? loggedInText : loggedOutText;

    return (
      <div className='not-found-page'>
        <div className='not-found-container'>
          <img className='first-round-engineering-img' src={FirstRoundEngineeringImage} />
          <div className='not-found-text'>
            <h1>404 Not Found</h1>
            <h4>
              <Link to={redirectLink}>Return home</Link> {redirectText}
            </h4>
          </div>
        </div>
      </div>
    );
  }
}

NotFoundPage.propTypes = {
  user: PropTypes.object,
};

export default WithUser(NotFoundPage);
