import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { track } from 'actions/actions';

function WithTrackAction(WrappedComponent) {
  class WrappedWithTrackAction extends React.Component {
    render() {
      return <WrappedComponent {...this.props} />;
    }
  }

  WrappedWithTrackAction.propTypes = {
    track: PropTypes.func.isRequired,
  };

  function mapDispatchToProps(dispatch) {
    return {
      track: (request) => dispatch(track(request)),
    };
  }

  return connect(null, mapDispatchToProps)(WrappedWithTrackAction);
}

export default WithTrackAction;
