import React from 'react';
import PropTypes from 'prop-types';

import {
  CenterCardImage,
  CenterCardTitle,
  CenterCardSubtitle,
} from 'components/CenterCard';
import Modal from 'components/Modal';
import FeaturedCandidateStar from 'components/FeaturedCandidateStar';
import IntroRequestForm from 'components/IntroRequestForm';

import UserIcon from 'images/user.svg';
import 'stylesheets/application/contact-candidate-modal.less';

class ContactCandidateModal extends React.Component {
  render() {
    const imageSource = this.props.candidate.image_url ? this.props.candidate.image_url : UserIcon;

    return (
      <Modal
        className='candidate-card-contact-modal'
        open={this.props.open}
        onClose={this.props.closeModal}
      >
        <div className='flex flex-column flex-center'>
          <div className='featured-candidate-star-container'>
            <FeaturedCandidateStar featured={this.props.candidate.featured} />
          </div>
          <CenterCardImage type='circle' src={imageSource} fallback={UserIcon} />
          <CenterCardTitle>
            {this.props.candidate.first_name} {this.props.candidate.last_name}
          </CenterCardTitle>
          <CenterCardSubtitle>
            {this.props.candidate.title} at <strong>{this.props.candidate.company_name}</strong>
          </CenterCardSubtitle>
          <React.Fragment>
            <p className='help-text'>
              {this.props.candidate.featured &&
                <span>The First Round talent team is working directly with {this.props.candidate.first_name}. </span>
              }
              <span>If you would like to speak with {this.props.candidate.first_name}, request an introduction and we{`'`}ll reach out for the double opt-in.</span>
            </p>
          </React.Fragment>
        </div>
        <IntroRequestForm
          createIntroRequest={this.props.createIntroRequest}
        />
      </Modal>
    );
  }
}

ContactCandidateModal.propTypes = {
  candidate: PropTypes.object.isRequired,
  currentUser: PropTypes.object.isRequired,
  createIntroRequest: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
};

export default ContactCandidateModal;
