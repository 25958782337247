import React from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';

import WithUser from 'components/WithUser';

class AdminRoute extends React.Component {
  render() {
    if (!(this.props.user && this.props.user.admin)) {
      return <Redirect to='/welcome/' />;
    }

    return (
      <Route {...this.props}></Route>
    );
  }
}

AdminRoute.propTypes = {
  user: PropTypes.object,
};

export default WithUser(AdminRoute);
