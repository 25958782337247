import { Redirect } from 'react-router-dom';

/**
 * Redirect componet with an override to its perform() method that triggers a window-level redirect,
 * instead of an SPA router redirect.
 * 
 * See: https://www.npmjs.com/package/react-router/v/4.3.1
 * Navigate to file: /react-router/es/Redirect.js
 */
class HardRedirect extends Redirect {
  perform() {
    const push = this.props.push;
    const to = this.computeTo(this.props);

    if (push) {
      window.location.assign(to);
    } else {
      window.location.replace(to);
    }
  }
}

export default HardRedirect;
