import { sortBy, isEmpty } from 'lodash';
import moment from 'moment';

function collapseCandidateDesiredCompanySizes(sizes) {
  sizes = candidateDesiredCompanySizeSort(sizes);

  if (sizes.length === 5) {
    return `Any`;
  } else {
    return sizes.join(`, `);
  }
}

function candidateDesiredCompanySizeSort(sizes) {
  const options = [
    `Founding (1-10 employees)`,
    `Seed (11-50 employees)`,
    `Early (51-100 employees)`,
    `Mid (101-250 employees)`,
    `Growth (201-500 employees)`,
    `Large (501-1000 employees)`,
    `Mature (1001+ employees)`
  ];

  return sizes.sort((sizeA, sizeB) => {
    return options.indexOf(sizeA.value) - options.indexOf(sizeB.value);
  });
}

function whenLookingSort(users) {
  const options = [
    `Right now`,
    `In the next 3 months`,
    `In the next 3-6 months`,
    `In the next 6-12 months`,
    `I'm not looking - but always open to new opportunities`,
  ];

  return users.sort((userA, userB) => {
    return options.indexOf(userA.value) - options.indexOf(userB.value);
  });
}

function experienceLevelSort(items) {
  const valueOrder = ['Early Career (0-2 years)', 'Mid Career (3-8 years)', 'Late Career (9+ years)', 'Not Specified'];
  const orderedItems = [];
  
  for (let i = 0; i < valueOrder.length; i++) {
    const item = items.find(item => item.value === valueOrder[i]);
    if (item) orderedItems.push(item);
  }
  
  return orderedItems;
}

function alphabeticalSort(items) {
  return sortBy(items, `value`);
}

// Helper that takes a list of objects with id's and transforms them
// into a hash where the index is the id.
function recordListToHash(recordList, identifier) {
  return recordList.reduce((hash, record) => {
    hash[record[identifier]] = record;
    return hash;
  }, {});
}

function requiredFormValidator(value) {
  if (typeof(value) === `number` || typeof(value) === `boolean`) {
    return undefined;
  }

  return isEmpty(value) ? `Required` : undefined;
}

function emailValidator(value) {
  if (isEmpty(value)) {
    return `Required`;
  }

  if (!/^.+@.+\..+$/.test(value)) {
    return `Please enter a valid email`;
  }
  return undefined;
}

function linkedInFormValidator(value) {
  if (typeof(value) === `number` || typeof(value) === `boolean`) {
    return undefined;
  }
  value = value.trim();
  // RegEx for LinkedIn URLs
  if (/^(http(s)?:\/\/)?([\w]+\.)?linkedin\.com\/(pub|in|profile)/.test(value) ) {
    return undefined;
  } else {
    return `Please use a valid LinkedIn URL`;
  }
}

function websiteValidator(value) {
  if (isEmpty(value)) {
    // allow empty/null websites for user fields
    return undefined;
  }
  let url;

  try {
    url = new URL(value);
  } catch (_) {
    return `Please enter a valid url`;
  }

  if(url.protocol === `http:` || url.protocol === `https:`) {
    return undefined;
  }
  return `Please enter a valid url`;
}

function gitHubUrlValidator(value) {
  if (isEmpty(value)) {
    // allow empty/null websites for github url
    return undefined;
  }

  if (/^(http(s)?:\/\/)?([\w]+\.)?github\.com\/[a-zA-Z0-9_]{1,25}$/igm.test(value) ) {
    return undefined;
  } else {
    return `Please use a valid GitHub URL`;
  }
}

function googleUrlValidator(value) {
  if (isEmpty(value)) {
    // allow empty/null websites for google docs links
    return undefined;
  }

  // Docs or Drive perhaps?
  if (/http(s)?:\/\/(drive|docs)\.google\.com\/(document|file)\/d\/(.*?)\/.*?\?usp=sharing/igm.test(value) ) {
    return undefined;
  } else {
    return `Please use a valid Google URL`;
  }
}

function limitMultiselectOptionsValidator(value) {
  return value.length && value.length > 10 ? `Select up to 10` : undefined;
}

function sanitizeNameField(value) {
  // Remove emojis
  value = value.replace(/([\u2700-\u27BF]|[\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u26FF]|\uD83E[\uDD10-\uDDFF])/g, ``);
  // Remove disallowed special chars
  return value.replace(/[`~!@#$%^*()_|+\-=?;:",.<>{}&0123456789[\]\\/]/gi, ``);
}

function getAbbreviatedTimeAgo(epochTime) {
  const unabbreviatedJoinedAt = moment(epochTime * 1000).fromNow();

  const abbreviationMap = {
    'a few seconds ago': `just now`,
    'a minute': `1min`,
    ' minutes': `min`,
    'an hour': `1h`,
    ' hours': `h`,
    'a day': `1d`,
    ' days': `d`,
    'a month': `1mo`,
    ' months': `mo`,
    'a year': `1y`,
    ' years': `y`,
  };

  return Object.keys(abbreviationMap).reduce(function (joinedAt, key) {
    return joinedAt.replace(key, abbreviationMap[key]);
  }, unabbreviatedJoinedAt);
}

function getInfoUpdatedRedirectLinkForUser(user) {
  if (user.admin) {
    return `/users`;
  } else if (user.hiringManager) {
    return `/hire`;
  } else {
    return `/jobs`;
  }
}

/**
 * Only allows letters from different alphabets, numbers, spaces,
 * and the following special characters: - ' & , . / \
 */
const LIMITED_SPECIAL_CHARACTERS_REGEX = /^[\p{Letter}\p{Mark}\s\d',./\\&-]+$/u


/**
 * Characters allowed are letters from different alphabets, numbers, spaces,
 * and the following special characters: - ' & , . / \
 * An empty string does not fail validation, so this validator can safely be used on optional fields.
 * @param {String} str
 * @returns {undefined} undefined if validation passes
 * @returns {String} error string if validation fails
 */
function limitSpecialCharactersValidator(str) {
  if (!str) return // Empty string passes validation so that this validator can safely be used on optional fields
  if (!LIMITED_SPECIAL_CHARACTERS_REGEX.test(str)) return `Please only use letters, numbers, spaces, and - ' & , . / \\`;
}

export {
  collapseCandidateDesiredCompanySizes,
  candidateDesiredCompanySizeSort,
  whenLookingSort,
  experienceLevelSort,
  getAbbreviatedTimeAgo,
  alphabeticalSort,
  recordListToHash,
  requiredFormValidator,
  linkedInFormValidator,
  limitMultiselectOptionsValidator,
  getInfoUpdatedRedirectLinkForUser,
  websiteValidator,
  gitHubUrlValidator,
  googleUrlValidator,
  sanitizeNameField,
  limitSpecialCharactersValidator,
  emailValidator,
};
