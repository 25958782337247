import React from 'react';
import PropTypes from 'prop-types';

import { Field } from 'redux-form';
import mapError from 'redux-form-material-ui/lib/mapError.js';

import MultiSelect from 'components/MultiSelect';

// redux-form passes the some special properties as props.input
// here we pass those properties directly to our MultiSelect component
// which has no sense of redux-form
const MultiSelectReduxWrapper = ({input, ...props}) => (
  <MultiSelect
    {...input}
    {...mapError(props)}
    {...props}
  />
);

const MultiSelectReduxField = ({ children, ...props}) => (
  <Field
    component={MultiSelectReduxWrapper}
    {...props}
  >
    {children}
  </Field>
);

MultiSelectReduxWrapper.propTypes = {
  input: PropTypes.any,
  children: PropTypes.any,
};

MultiSelectReduxField.propTypes = {
  children: PropTypes.any,
};

export default MultiSelectReduxField;
