import React from 'react';
import PropTypes from 'prop-types';

import { Grid, Row, Col } from 'react-bootstrap';

class PageContainer extends React.Component {

  render() {
    return (
      <Grid>
        <Row>
          <Col md={12}>
            {this.props.children}
          </Col>
        </Row>
      </Grid>
    );
  }
}

PageContainer.propTypes = {
  children: PropTypes.any.isRequired,
};

export default PageContainer;
