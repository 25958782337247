const sessionExpirationMiddleware = store => next => action => {
  const state = store.getState();
  const user = state.user;
  if (user && user.expiresAt) {
    const sessionExpired = new Date(user.expiresAt) < Date.now();
    if (sessionExpired) {
      location.reload();
    }
  }
  return next(action);
};

export default sessionExpirationMiddleware;
