import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { getSearchCurrentPage } from 'selectors/selectors';

import { setSearchPage, clear } from 'actions/actions';
import Paginator from 'components/Paginator';

class SearchPaginator extends React.Component {
  constructor(props) {
    super(props);
    this.onClickFirst = this.onClickFirst.bind(this);
    this.onClickPrevious = this.onClickPrevious.bind(this);
    this.onClickNext = this.onClickNext.bind(this);
    this.onClickLast = this.onClickLast.bind(this);
  }

  componentWillUnmount() {
    this.props.clear(`SEARCH_PAGE`);
  }

  getSearch() {
    return this.props.searches[this.props.searchType];
  }

  onClickFirst() {
    this.props.setSearchPage(0);
  }

  onClickPrevious() {
    this.props.setSearchPage(this.props.searchCurrentPage - 1);
  }

  onClickNext() {
    this.props.setSearchPage(this.props.searchCurrentPage + 1);
  }

  onClickLast(totalPages) {
    this.props.setSearchPage(totalPages - 1);
  }

  render() {
    const search = this.getSearch();

    if (!search) {
      return null;
    }

    const totalPages = search.nbPages;

    if (totalPages === 0) {
      return null;
    }

    return (
      <Paginator
        currentPage={this.props.searchCurrentPage}
        totalPages={totalPages}
        onClickFirst={this.onClickFirst}
        onClickPrevious={this.onClickPrevious}
        onClickNext={this.onClickNext}
        onClickLast={() => this.onClickLast(totalPages)}
      />
    );
  }
}

SearchPaginator.propTypes = {
  searches: PropTypes.object.isRequired,
  searchType: PropTypes.string.isRequired,
  namespace: PropTypes.string.isRequired,
  setSearchPage: PropTypes.func.isRequired,
  searchCurrentPage: PropTypes.number.isRequired,
  clear: PropTypes.func.isRequired,
};

function mapStateToProps(state, ownProps) {
  return {
    searches: state.searches,
    searchCurrentPage: getSearchCurrentPage(state, ownProps),
  };
}

function mapDispatchToProps(dispatch, ownProps) {
  return {
    setSearchPage: page => dispatch(setSearchPage(page, ownProps.namespace)),
    clear: clearType => dispatch(clear(clearType, ownProps.namespace)),
  };
}

const SearchPaginatorWithState = connect(
  mapStateToProps,
  mapDispatchToProps
)(SearchPaginator);

export default SearchPaginatorWithState;
