import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

function WithUser(WrappedComponent) {
  class WrappedWithUser extends React.Component {
    render() {
      return <WrappedComponent
        {...this.props}
        user={this.props.user}
      />;
    }
  }

  WrappedWithUser.propTypes = {
    user: PropTypes.object,
  };

  function mapStateToProps(state) {
    return {
      user: state.user,
    };
  }

  return connect(mapStateToProps)(WrappedWithUser);
}

export default WithUser;
