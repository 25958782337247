import React from 'react';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { createMessage } from 'actions/actions';

import WithUser from 'components/WithUser';
import CenterCandidateCard from 'components/CenterCandidateCard';
import WithSearch from 'components/WithSearch';
import NotFoundPage from 'components/NotFoundPage';
import WithResourceActions from 'components/WithResourceActions';
import Button from 'components/Button';

import 'stylesheets/application/candidate-page.less';

class CandidatePage extends React.Component {
  componentDidMount() {
    this.loadIntroRequests();
    this.searchForCandidate()
      .then(response => {
        if (this.props.user.admin && this.candidateNotApproved(response)) {
          this.props.createMessage({
            body: `This candidate has not been approved. Only First Round admins may view this page.`,
            dismissable: true,
            type: `warning`,
            expires: false,
          });
        }
      });
  }

  loadIntroRequests() {
    const request = {
      url: `/intro_requests`,
      store: `introRequests`,
    };
    this.props.getResources(request);
  }

  candidateNotApproved(response) {
    return response.hits.length === 1 && response.hits[0].status !== `approved`;
  }

  getCandidateSlug() {
    return this.props.match.params.id;
  }

  searchForCandidate() {
    const request = {
      params: {
        hitsPerPage: 1,
        filters: [
          {
            operator: `OR`,
            name: `slug`,
            values: [this.getCandidateSlug()],
          },
        ],
      },
      query: ``,
      model: `User`,
      userType: this.props.user.admin ? `admin` : `hiringManager`,
      searchType: `SEARCH_TYPE_CANDIDATES_PERMALINK`,
    };

    return this.props.search(request);
  }

  render() {
    const candidateSearch = this.props.searches[`SEARCH_TYPE_CANDIDATES_PERMALINK`];

    if (!candidateSearch) {
      return null;
    }

    const candidateSearchResult = candidateSearch.hits[0];

    if (!candidateSearchResult) {
      return <NotFoundPage />;
    }

    return (
      <div className='candidate-page'>
        <CenterCandidateCard candidate={candidateSearchResult} />
        <div className='more-candidates'>
          <h4>Not a fit? Find candidates in similar roles and locations.</h4>
          <Button
            tagType='internalLink'
            to='/hire'
          >
            More Candidates
          </Button>
        </div>
      </div>
    );
  }
}

CandidatePage.propTypes = {
  searches: PropTypes.object.isRequired,
  search: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  getResources: PropTypes.func.isRequired,
  createMessage: PropTypes.func.isRequired,
};

export default compose(
  WithUser,
  WithResourceActions,
  WithSearch,
  connect(
    (state) => ({
      searches: state.searches,
    }),
    (dispatch) => ({
      createMessage: (payload) => dispatch(createMessage(payload)),
    })
  )
)(CandidatePage);
