import React from 'react';
import PropTypes from 'prop-types';
import { OverlayTrigger, Glyphicon, Tooltip } from 'react-bootstrap';

import 'stylesheets/application/linked-glyphicon-with-tooltip.less';

class LinkedGlyphiconWithTooltip extends React.Component {
    render() {
      const GlyphiconTooltip = (
        <Tooltip id="tooltip" className='glyphicon-tooltip'>
          {this.props.tooltipText}
        </Tooltip>
      );

      return (
        <OverlayTrigger placement={this.props.tooltipPlacement} overlay={GlyphiconTooltip}>
          <div className='glyphicon-button'>
            {this.props.isDownloadEnabled ? (
              <a href={this.props.url} download target="_blank">
                <label>Download Current File</label>
                <Glyphicon className='glyphicon-icon' glyph='download-alt'/>
              </a>
            ) : (
              <a href={this.props.url} target="_blank">
                <Glyphicon className='glyphicon-icon' glyph={this.props.glyphiconKey}/>
              </a>
            )}
          </div>
        </OverlayTrigger>
      );
    }
  }

LinkedGlyphiconWithTooltip.propTypes = {
  url: PropTypes.string.isRequired,
  glyphiconKey: PropTypes.string.isRequired,
  tooltipText: PropTypes.string.isRequired,
  isDownloadEnabled: PropTypes.bool,
  tooltipPlacement: PropTypes.string,
};
LinkedGlyphiconWithTooltip.defaultProps = {
  isDownloadEnabled: false,
  tooltipPlacement: 'right'
}

export default LinkedGlyphiconWithTooltip;
