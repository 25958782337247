import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

import ErrorBoundary from 'components/ErrorBoundary';
import AdminRoute from 'components/AdminRoute';
import HiringManagerRoute from 'components/HiringManagerRoute';
import CandidateRoute from 'components/CandidateRoute';
import RequireLoggedIn from 'components/RequireLoggedIn';
import SignUpPage from 'components/SignUpPage';
import UserInfoPage from 'components/UserInfoPage';
import TopNavBar from 'components/TopNavBar';
import PageContainer from 'components/PageContainer';
import AdminUsersPage from 'components/AdminUsersPage';
import HiringManagerSignUpPage from 'components/HiringManagerSignUpPage';
import InternalServerErrorPage from 'components/InternalServerErrorPage';
import DashboardPage from "components/DashboardPage";
import CandidatesPage from 'components/CandidatesPage';
import CandidatePage from 'components/CandidatePage';
import Messages from 'components/Messages';
import OffboardingFollowupModal from 'components/OffboardingFollowupModal';
import OffboardingModal from 'components/OffboardingModal';
import OffboardingMessage from 'components/OffboardingMessage';

class App extends React.Component {
  render() {
    return (
      <div>
        <Messages />
        <RequireLoggedIn>
          <TopNavBar />
        </RequireLoggedIn>
        <OffboardingFollowupModal />
        <OffboardingModal />
        <OffboardingMessage />
        <ErrorBoundary>
          <PageContainer>
            <Switch>
              {/* Custom Routes */}
              <Route path='/welcome/:code?' component={SignUpPage} />
              <Route exact path='/hire/sign-up' component={HiringManagerSignUpPage} />
              <Route exact path='/internal-server-error' component={InternalServerErrorPage} />

              {/* Candidate Routes */}
              <CandidateRoute exact path='/info' component={UserInfoPage} />

              {/* Hiring Manager Resources */}
              <HiringManagerRoute exact path='/status' component={DashboardPage} />
              <HiringManagerRoute exact path='/hire' component={CandidatesPage} />
              <HiringManagerRoute exact path='/candidates/:id' component={CandidatePage} />

              {/* Admin Routes*/}
              <AdminRoute exact path='/users' component={AdminUsersPage} />

              {/* Fallback redirects to welcome page, which sends logged in users to their 'home page' */}
              <Redirect to='/welcome' />
            </Switch>
          </PageContainer>
        </ErrorBoundary>
      </div>
    );
  }
}

export default App;
