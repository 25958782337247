class AlgoliaSearchError extends Error {
  constructor(message) {
    super(message);
    this.body = `AlgoliaSearchError`;
  }
}

export {
  AlgoliaSearchError,
};
