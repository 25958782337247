import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Col} from 'react-bootstrap';

import { submitPassword, trackCandidateEvent } from 'actions/actions';

import LoggedOutPage from 'components/LoggedOutPage';
import SignUpModalContents from 'components/SignUpModalContents';
import Modal from 'components/Modal';
import Button from 'components/Button';
import { createMessage } from '../actions/actions';

import CareersLogo from 'images/first_round_careers_logo.png';
import 'stylesheets/application/signup.less';
import MagicLinkModalContents from './MagicLinkModalContents';
import { sendMagicLink } from '../actions/actions';

class SignUpPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      validLinkedinUrl: null,
      validPassword: null,
      signUpClicked: false,
      logInClicked: false,
      magicLinkClicked: false,
    };
    this.onSubmitMagicLink = this.onSubmitMagicLink.bind(this);
    this.toggleModal = this.toggleModal.bind(this);
    this.handleSignUpSubmit = this.handleSignUpSubmit.bind(this);
  }

  componentDidMount() {
    trackCandidateEvent(`view`, `welcome`, null);
  }

  toggleModal(isOpen) {
    this.setState({ isModalOpen: isOpen });
  }

  getModalType() {
    if (this.state.signUpClicked) {
      return `SignUpModal`;
    } else if (this.state.magicLinkClicked) {
      return `MagicLinkModal`;
    }
  }

  onSubmitMagicLink(values) {
    trackCandidateEvent(`submit`, `welcome`, null, `button-magic-link`);
    this.props.sendMagicLink(values.email)
      .then(() => {
        this.props.createMessage({
          body: `Login link sent to ${values.email}`,
          dismissable: true,
          type: `success`,
          expires: true,
        });
        this.setState({magicLinkClicked: false});
      })
      .catch(error => {
        this.props.createMessage({
          body: error.body.error,
          dismissable: true,
          type: `error`,
          expires: false,
        });
      });
  }

  handleSignUpSubmit(values) {
    trackCandidateEvent(`submit`, `welcome`, null, `button-sign-up`);
    return fetch('/users/sign_up', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        first_name: values.first_name,
        last_name: values.last_name,
        email: values.email,
        linkedin_url: values.linkedin_url,
        entry_password_used: values.sign_up_code,
        referral_medium: 'Personal Referral',
        onboarding_status: 'incomplete',
      }),
    })
    .then(response => response.json())
    .then(data => {
      if (data.error) {
        this.props.createMessage({
          body: data.error,
          dismissable: true,
          type: 'error'
        });
        this.setState({ errorMessage: data.error });
      } else {
        this.props.createMessage({
          body: 'Account created successfully. Please check your email to log in.',
          dismissable: true,
          type: 'success'
        });
        this.setState({ signUpClicked: false });
      }
    })
    .catch(error => {
      console.error("Sign-up error:", error);
      this.props.createMessage({
        body: "An error occurred. Please contact careers@firstround.com",
        dismissable: true,
        type: 'error'
      });
    });
  }
  

  render() {
    const modalType = this.getModalType();

    return (
      <Col md={8} mdOffset={2} className="signup text-center">
        <Modal
          open={modalType === `SignUpModal`}
          onClose={() => this.setState({signUpClicked: false})}
        >
          <SignUpModalContents
            onSubmit={this.handleSignUpSubmit}
          />
        </Modal>
        <Modal
          open={modalType === `MagicLinkModal`}
          onClose={() => {
            this.setState({magicLinkClicked: false});
            trackCandidateEvent(`click`, `welcome`, null, `button-open-sign-in`);
          }}
        >
          <MagicLinkModalContents onSubmit={this.onSubmitMagicLink} />
        </Modal>
        <img
          src={CareersLogo}
          className="logo center-block"
        />
        <div className="body">
          <h1>Matching you with world&#8209;changing technology companies</h1>
          <div className='public-signup'>
            <div className='signup-button-container'>
              <Button
                displayType='primary'
                onClick={() => {
                  this.setState({signUpClicked: true});
                  trackCandidateEvent(`click`, `welcome`, null, `button-open-sign-up`);
                }}
              >
                Sign Up
              </Button>
            </div>
          </div>
          <hr />
          <div className='login'>
            Already have an account? &nbsp;
            <div className='login-buttons'>
              <Button
                displayType='default'
                tagType='button'
                onClick={() => this.setState({magicLinkClicked: true})}
                id='button-magic-link'
              >
                Email me a login link
              </Button>
            </div>
          </div>
        </div>
      </Col>
    );
  }
}

SignUpPage.propTypes = {
  submitPassword: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired,
  createMessage: PropTypes.func.isRequired,
  sendMagicLink: PropTypes.func.isRequired,
};

function mapDispatchToProps(dispatch) {
  return {
    submitPassword: (request) => dispatch(submitPassword(request)),
    createMessage: (payload) => dispatch(createMessage(payload)),
    sendMagicLink: (email) => dispatch(sendMagicLink(email)),
  };
}

const SignUpPageWithState = connect(
  null,
  mapDispatchToProps
)(SignUpPage);

export default LoggedOutPage(SignUpPageWithState);
