import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { capitalize } from 'lodash';

import { getAbbreviatedTimeAgo } from 'helpers/application';

import {
  CenterCard,
  CenterCardImage,
  CenterCardFooter,
  CenterCardBody,
  CenterCardTitle,
  CenterCardSubtitle,
  CenterCardDetail,
  CenterCardNotice,
  CenterCardContent,
  CenterCardFooterCenter,
  CenterCardTopRight,
  CenterCardTopLeft,
  CenterCardFooterDetail,
} from 'components/CenterCard';
import WithTrackAction from 'components/WithTrackAction';
import { LinkedinIcon } from 'components/Svg';
import Popover from 'components/Popover';
import WithResourceActions from 'components/WithResourceActions';
import Button from 'components/Button';
import AdminManageCandidateButtons from 'components/AdminManageCandidateButtons';
import AdminCandidateModal from 'components/AdminCandidateModal';
import { ChevronDown } from 'components/Svg';
import LinkedGlyphiconWithTooltip from 'components/LinkedGlyphiconWithTooltip';

import UserIcon from 'images/user.svg';

import 'stylesheets/application/admin-candidate-card.less';

const STATUS_BUTTON_DISPLAY_TYPE_MAP = {
  pending: `notice`,
  approved: `primary`,
  denied: `warning`,
  removed: `warning`,
  candidate_declined: `warning`,
  hired_by_community: `primary`,
  introductory_conversation: `primary`,
  inactive: `warning`,
  no_longer_available: `warning`,
};

const STATUS_BUTTON_DISABLED = [
  `denied`,
  `removed`,
  `hired_by_community`,
  `candidate_declined`,
  `inactive`,
  `no_longer_available`,
];

class CenterCandidateCard extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      editStatus: false,
    };
  }

  componentDidMount() {
    const request = {
      url: `/admin/users/${this.props.candidate.objectID}`,
      store: `users`,
    };
    this.props.getResource(request);
  }

  getLookingIn() {
    if (this.props.candidateResource.regionNames.length > 1) {
      return `Multiple Locations`;
    }

    return this.props.candidateResource.regionNames[0];
  }

  getIndustries() {
    if (this.props.candidateResource.industryNames.length > 1) {
      return `Multiple Industries`;
    }

    return this.props.candidateResource.industryNames[0];
  }

  getNotice() {
    const previouslyApproved = !!this.props.candidateResource.approvedAt && status === `pending`;

    if (!previouslyApproved) {
      return;
    }

    return (
      <CenterCardNotice>
        This candidate was previously approved.
      </CenterCardNotice>
    );
  }

  render() {
    if (!this.props.candidateResource) {
      return null;
    }

    const candidateFullName =`${this.props.candidateResource.firstName} ${this.props.candidateResource.lastName}`;
    const imageSource = this.props.candidateResource.imageUrl ? this.props.candidateResource.imageUrl : UserIcon;
    const resumeSource = this.props.candidateResource.resumeUrl || this.props.candidateResource.secondaryResumeUrl;
    const joinedAt = (this.props.candidateResource.lastSignInAtEpoch === 0) ? `never` : getAbbreviatedTimeAgo(this.props.candidateResource.lastSignInAtEpoch);
    const lookingIn = this.getLookingIn();
    const industries = this.getIndustries();
    const status = this.props.candidateResource.status;
    const statusButtonText = capitalize(status.split(`_`).join(` `));
    const statusButtonDisplayType = STATUS_BUTTON_DISPLAY_TYPE_MAP[status];
    const statusButtonDisabled = STATUS_BUTTON_DISABLED.includes(status);
    const salesforceId = this.props.candidateResource.salesforceTalentEngagementId;
    const incompleteProfile = this.props.candidateResource.onboardingStatus === `incomplete`;
    const notice = this.getNotice();

    return (
      <CenterCard className='admin-candidate-card'>
        <CenterCardTopLeft withNotice={!!notice}>
          {salesforceId &&
            <Button
              tagType='externalLink'
              displayType='notice'
              href={`https://firstround.my.salesforce.com/${this.props.candidateResource.salesforceTalentEngagementId}`}
              target='_blank'
              rel='noopener noreferrer'
            >
              View in Salesforce
            </Button>
          }
          {!this.props.candidateResource.sharingAllowed &&
            <Button displayType='notice' disabled>
              Cannot Share
            </Button>
          }
          {incompleteProfile &&
            <Button displayType='notice' disabled>
              Incomplete Profile
            </Button>
          }
        </CenterCardTopLeft>
        <CenterCardTopRight withNotice={!!notice}>
          <AdminCandidateModal candidate={this.props.candidate} />
        </CenterCardTopRight>
        {notice}
        <CenterCardContent withNotice={!!notice}>
          <CenterCardBody>
            <CenterCardImage type='circle' src={imageSource} fallback={UserIcon} />
            <CenterCardTitle>
            <div className='candidate-title-container'>
              <div>{candidateFullName}</div>
              {resumeSource && (
                <LinkedGlyphiconWithTooltip
                  url={resumeSource}
                  glyphiconKey={'new-window'}
                  tooltipText={'View the candidate\'s resume'}
                />
              )}
            </div>
            </CenterCardTitle>
            <CenterCardSubtitle>
              {this.props.candidateResource.title} at <strong>{this.props.candidateResource.companyName}</strong>
            </CenterCardSubtitle>
            <CenterCardDetail
              content={this.props.candidateResource.primaryFunctionalAreaName}
              prefix='Interested in'
            />
            <Popover
              disablePopover={this.props.candidateResource.regionNames.length <= 1}
              parent={
                <CenterCardDetail
                  content={lookingIn}
                  prefix='Looking in'
                />
              }
              popover={
                <div>
                  {this.props.candidateResource.regionNames.join(`, `)}
                </div>
              }
            />
            <Popover
              disablePopover={this.props.candidateResource.industryNames.length <= 1}
              parent={
                <CenterCardDetail
                  content={industries}
                  prefix='Industries'
                />
              }
              popover={
                <div>
                  {this.props.candidateResource.industryNames.join(`, `)}
                </div>
              }
            />
            <CenterCardDetail
              content={this.props.candidateResource.skillNames.join(`, `)}
              prefix='Skills'
            />
            <CenterCardDetail
              content={this.props.candidateResource.roleTypeNames.join(`, `)}
              prefix='Role Type(s)'
            />
            <CenterCardDetail
              content={this.props.candidateResource.experienceLevelName}
              prefix='Experience'
            />
            <CenterCardDetail
              content={this.props.candidateResource.whenLooking}
              prefix='Timeline'
            />
            <CenterCardDetail
              content={this.props.candidateResource.assignedTo}
              prefix='Assigned to'
            />
            <CenterCardDetail
              content={this.props.candidateResource.referralSource}
              prefix='Referred by'
            />
            {this.props.candidateResource.requiresSponsorship && (
              <CenterCardDetail
                content='Requires Visa Sponsorship'
              />
            )}
          </CenterCardBody>
          <CenterCardFooter>
            {!this.state.editStatus &&
              <React.Fragment>
                <CenterCardFooterCenter>
                  <div className='status-button-container'>
                    <Button
                      displayType={statusButtonDisplayType}
                      onClick={() => this.setState({ editStatus: true })}
                      disabled={statusButtonDisabled}
                    >
                      {statusButtonText}
                      {!statusButtonDisabled &&
                        <ChevronDown />
                      }
                    </Button>
                  </div>
                </CenterCardFooterCenter>
                <CenterCardFooterDetail>
                  Last login {joinedAt}
                </CenterCardFooterDetail>
                <CenterCardFooterDetail>
                  {this.props.candidateResource.linkedinUrl &&
                    <a
                      href={this.props.candidateResource.linkedinUrl}
                      className='linkedin-link'
                      target='_blank'
                      rel='noopener noreferrer'
                    >
                      <span>View on LinkedIn</span>
                      <LinkedinIcon />
                    </a>
                  }
                </CenterCardFooterDetail>
              </React.Fragment>
            }
            {this.state.editStatus &&
              <AdminManageCandidateButtons
                userId={this.props.candidate.objectID}
                onCancel={() => this.setState({ editStatus: false })}
              />
            }
          </CenterCardFooter>
        </CenterCardContent>
      </CenterCard>
    );
  }
}

CenterCandidateCard.propTypes = {
  currentUser: PropTypes.object.isRequired,
  candidate: PropTypes.object.isRequired,
  candidateResource: PropTypes.object,
  createResource: PropTypes.func.isRequired,
  getResource: PropTypes.func.isRequired,
  meta: PropTypes.object.isRequired,
  track: PropTypes.func.isRequired,
};

function mapStateToProps(state, ownProps) {
  return {
    candidateResource: state.users[ownProps.candidate.objectID],
    meta: state.meta,
    currentUser: state.user,
  };
}

const CenterCandidateCardWithState = connect(mapStateToProps)(CenterCandidateCard);

export default WithTrackAction(WithResourceActions(CenterCandidateCardWithState));
