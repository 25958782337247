import React from 'react';

import { whenLookingSort } from 'helpers/application';

import LoggedInPage from 'components/LoggedInPage';
import SearchResultsList from 'components/SearchResultsList';
import SearchQueryBar from 'components/SearchQueryBar';
import SearchFiltersBar from 'components/SearchFiltersBar';
import SearchGroup from 'components/SearchGroup';
import SearchPaginator from 'components/SearchPaginator';
import AdminCandidateCard from 'components/AdminCandidateCard';

class AdminUsersPage extends React.Component {
  render() {
    const facets = [
      {
        field: `primary_functional_area_name`,
        fieldDisplayName: `Role`,
      },
      {
        field: `region_names`,
        fieldDisplayName: `Region`,
      },
      {
        field: `when_looking`,
        fieldDisplayName: `Urgency`,
        sort: (items) => whenLookingSort(items),
      },
      {
        field: `assigned_to`,
        fieldDisplayName: `Assigned To`,
      },
      {
        field: `referral_medium`,
        fieldDisplayName: `Referral Channel`,
      },
      {
        field: `experience_level_name`,
        fieldDisplayName: `Experience Level`,
      },
      {
        field: `sharing_allowed`,
        fieldDisplayName: `Sharing Allowed`,
      },
      {
        field: `requires_sponsorship`,
        fieldDisplayName: `Requires Sponsorship`,
      },
      {
        field: `status`,
        fieldDisplayName: `Triage Status`,
      },
      {
        field: `skill_names`,
        fieldDisplayName: `Skills`,
      },
      {
        field: `industry_names`,
        fieldDisplayName: `Desired Industries`,
      },
      {
        field: `role_type_names`,
        fieldDisplayName: `Role Types`,
      },
    ];

    const filters = [
      {
        name: `candidate`,
        values: [true],
        operator: `OR`,
      },
    ];

    return (
      <div>
        <SearchGroup>
          <SearchQueryBar
            namespace='usersPage'
            placeholder='Search by name, company, title, skills...'
          />
          <SearchFiltersBar
            model='User'
            userType='admin'
            searchType='SEARCH_TYPE_USERS_LIST_ALL_USERS'
            namespace='usersPage'
            facets={facets}
            parentFilters={filters}
            dropdownSubtitleType='candidates'
          />
        </SearchGroup>
        <SearchResultsList
          model='User'
          userType='admin'
          searchType='SEARCH_TYPE_USERS_LIST'
          countType='Candidates'
          parentFilters={filters}
          resultComponentCreator={(candidate) => (
            <AdminCandidateCard candidate={candidate} key={candidate.objectID} />
          )}
          namespace='usersPage'
          twoColumnList
        />
        <SearchPaginator
          searchType='SEARCH_TYPE_USERS_LIST'
          namespace='usersPage'
        />
      </div>
    );
  }
}

export default LoggedInPage(AdminUsersPage);
