import React from 'react';
import ReactDOM from 'react-dom';
import { ConnectedRouter } from 'connected-react-router';
import { createBrowserHistory } from 'history';

import App from 'components/App';
import { Provider } from 'react-redux';
import { getStore, getEnhancer, getMiddlewares } from 'helpers/initialize';
import { ENVIRONMENTS } from 'constants/constants';

import 'stylesheets/application.less';
import 'stylesheets/bootstrap.less';

const getPreloadedState = function() {
  const preloadedStateElement = document.getElementById(`preloaded-state`);
  return JSON.parse(preloadedStateElement.getAttribute(`data-preloadedstate`));
};

const getReactAppContainer = function() {
  return document.getElementById(`react-app`);
};

document.addEventListener(`DOMContentLoaded`, () => {
  // React will look for a `react-app` div embedded in the Rails
  // template that it will load all react rendered contents into.
  const reactAppContainer = getReactAppContainer();
  const history = createBrowserHistory();
  const preloadedState = getPreloadedState();
  const isProd = preloadedState.config.env === ENVIRONMENTS.PRODUCTION;
  const middlewares = getMiddlewares(history, preloadedState, isProd);
  const enhancer = getEnhancer(middlewares, isProd);
  const store = getStore(preloadedState, history, enhancer);

  // Expose easy access to state in development environments.
  if (!isProd) {
    window.getState = store.getState;
  }

  if (reactAppContainer) {
    ReactDOM.render(
      (
        <Provider store={store}>
          <ConnectedRouter history={history}>
            <App />
          </ConnectedRouter>
        </Provider>
      ),
      document.getElementById(`react-app`)
    );
  }
});
