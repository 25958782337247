import React from 'react';
import PropTypes from 'prop-types';
import { compose, withProps, withState } from 'recompose';

import { ControlLabel,  FormGroup, FormControl } from 'react-bootstrap';

import Button from 'components/Button';
import 'stylesheets/application/intro-request-form.less';

const IntroRequestForm = props => {
  const {
    handleSubmit,
    disabled,
    jobTitle,
    setJobTitle,
    jobUrl,
    setJobUrl,
  } = props;

  return (
    <form className='intro-request-form' onSubmit={handleSubmit}>
      <FormGroup>
        <ControlLabel>Role to discuss</ControlLabel>
        <FormControl className="job-title-input" onChange={e => setJobTitle(e.target.value)} value={jobTitle} />
        <ControlLabel>Link to Role</ControlLabel>
        <FormControl className="job-url-input" onChange={e => setJobUrl(e.target.value)} value={jobUrl} />
      </FormGroup>
      <Button
        displayType={disabled ? `default` : `primary`}
        tagType="button"
        type="submit"
        disabled={disabled}
      >
        Intro Me!
      </Button>
    </form>
  );
};

IntroRequestForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  setJobTitle: PropTypes.func.isRequired,
  jobTitle: PropTypes.string.isRequired,
  jobUrl: PropTypes.string.isRequired,
  setJobUrl: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
};

export default compose(
  withState(`jobTitle`, `setJobTitle`, ``),
  withState(`jobUrl`, `setJobUrl`, ``),
  withProps(props => ({
    handleSubmit: (e) => {
      const { jobTitle, jobUrl, createIntroRequest } = props;
      e.preventDefault();
      createIntroRequest({ jobId: null, jobTitle, jobUrl });
    },
    disabled: !props.jobTitle || !props.jobUrl,
  }))
)(IntroRequestForm);
