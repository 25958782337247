import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Glyphicon } from 'react-bootstrap';

import { clearMessage } from 'actions/actions';

import 'stylesheets/application/messages.less';

class Messages extends React.Component {
  getMessagesHtml() {
    return this.props.messages.map(message => {
      return (
        <div className='message-box-container' key={message.id}>
          <div className={`message-box ${message.type}`}>
            {message.dismissable &&
              <Glyphicon
                className='close'
                onClick={() => this.props.clearMessage(message)}
                glyph="remove-circle"
              />
            }
            {message.body}
          </div>
        </div>
      );
    });
  }

  render() {
    const messagesHtml = this.getMessagesHtml();

    return (
      <div className='messages-container'>
        {messagesHtml}
      </div>
    );
  }
}

Messages.propTypes = {
  messages: PropTypes.array,
  clearMessage: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  return {
    messages: state.messages,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    clearMessage: message => dispatch(clearMessage(message)),
  };
}

const MessagesWithState = connect(
  mapStateToProps,
  mapDispatchToProps
)(Messages);

export default MessagesWithState;
