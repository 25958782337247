import React from 'react';
import PropTypes from 'prop-types';

import { Field } from 'redux-form';
import { TextField } from 'redux-form-material-ui';

const StyledTextField = ({ classes, children, ...props }) => (
  <Field
    component={TextField}
    fullWidth={true}
    className={classes.textField}
    InputLabelProps={{className: classes.label}}
    InputProps={{className: classes.label}}
    FormHelperTextProps={{className: classes.helpText}}
    {...props}
  >
    {children}
  </Field>
);

StyledTextField.propTypes = {
  classes: PropTypes.object.isRequired,
  children: PropTypes.any,
};

export default StyledTextField;
