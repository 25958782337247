import React from 'react';
import PropTypes from 'prop-types';

import WithResourceActions from 'components/WithResourceActions';
import LoggedInPage from 'components/LoggedInPage';
import TalentIntros from 'components/TalentIntros';

class DashboardPage extends React.Component {
  componentDidMount() {
    this.loadTalentIntros();
  }

  loadTalentIntros() {
    const request = {
      url: `/talent_intros`,
      store: `talentIntros`,
    };
    this.props.getResources(request);
  }

  render() {
    return (
      <div className='search-results-list'>
        <TalentIntros/>
      </div>
    );
  }
}

DashboardPage.propTypes = {
  getResources: PropTypes.func.isRequired,
};

export default WithResourceActions(LoggedInPage(DashboardPage));
