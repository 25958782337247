import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Nav, Navbar, NavItem, NavDropdown, MenuItem } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';

import { onboardingComplete } from 'selectors/selectors';
import { createMessage, toggleAdminMode, getAdminStats } from 'actions/actions';

import RequireAdmin from 'components/RequireAdmin';
import RequireCandidate from 'components/RequireCandidate';
import RequireHiringManager from 'components/RequireHiringManager';
import RequireLoggedIn from 'components/RequireLoggedIn';
import Badge from 'components/Badge';
import { ChevronDown } from 'components/Svg';
import Button from 'components/Button';
import NonRouteNavItem from './NonRouteNavItem';

import CareersLogo from 'images/first_round_logo.png';
import 'stylesheets/application/top-nav-bar.less';

class TopNavBar extends React.Component {
  constructor(props) {
    super(props);

    this.handleAdminClick = this.handleAdminClick.bind(this);
    this.toggleAdminMode = this.toggleAdminMode.bind(this);
  }

  componentDidMount() {
    if (this.props.user.admin) {
      this.props.getAdminStats();
    }
  }

  handleAdminClick(message) {
    this.props.createMessage({
      body: message,
      dismissable: true,
      type: `notice`,
      expires: true,
    });
  }

  toggleAdminMode() {
    this.props.toggleAdminMode();
  }

  render() {
    if (!this.props.onboardingComplete) {
      return null;
    }

    return (
      <Navbar collapseOnSelect className="custom-nav">
        <Navbar.Header>
          <Navbar.Brand>
            { /* Hard redirect yields a GET to the server root,
              allowing the server to serve the proper "home" page for this user. */}
            <a href='/'>
              <img src={CareersLogo} className="navbar-logo" />
            </a>
          </Navbar.Brand>
          <Navbar.Toggle />
        </Navbar.Header>
        <Navbar.Collapse>
          <Nav activeKey={this.props.pathname}>
            {/* Using a React.Fragment to combine jobs and companies breaks
              the NavBar collapseOnSelect */}
            <RequireCandidate>
              <NonRouteNavItem to="/job-board">Jobs</NonRouteNavItem>
            </RequireCandidate>
            <RequireCandidate>
              <NonRouteNavItem to="/companies">Companies</NonRouteNavItem>
            </RequireCandidate>
            <RequireHiringManager>
              <LinkContainer to="/status">
                <NavItem eventKey="/status">Status</NavItem>
              </LinkContainer>
            </RequireHiringManager>
            <RequireHiringManager>
              <LinkContainer to="/hire">
                <NavItem eventKey="/hire">Hire</NavItem>
              </LinkContainer>
            </RequireHiringManager>
            <RequireHiringManager>
              <NonRouteNavItem to="/roles">Roles</NonRouteNavItem>
            </RequireHiringManager>
            <RequireAdmin>
              <LinkContainer to="/users">
                <NavItem eventKey="/users">Candidates</NavItem>
              </LinkContainer>
            </RequireAdmin>
          </Nav>
          <RequireLoggedIn>
            <Nav pullRight>
              <NavDropdown
                eventKey={3}
                title={
                  <span>
                    <span className="title">Settings</span>
                    <ChevronDown />
                  </span>
                }
                id="basic-nav-dropdown"
              >
                {(this.props.user.candidate || this.props.user.admin) && (
                  <LinkContainer to="/info">
                    <MenuItem eventKey={3.2}>Your Info</MenuItem>
                  </LinkContainer>
                )}
                <MenuItem divider />
                <MenuItem eventKey={3.3} href="/users/sign_out">
                  Logout
                </MenuItem>
              </NavDropdown>
            </Nav>
          </RequireLoggedIn>
          <RequireAdmin>
            <Nav pullRight>
              <NavDropdown title="Admin" id="basic-nav-dropdown">
                <MenuItem href="/users/auth/google_oauth2">
                  Connect to Gmail
                </MenuItem>
                <MenuItem href="/admin/users/new">New Candidate</MenuItem>
                <MenuItem href="/admin/users/new_hiring_manager">New Hiring Manager</MenuItem>
                <MenuItem href="/admin/entry_passwords">
                  New Referral Password
                </MenuItem>
                <MenuItem divider />
                <LinkContainer
                  to="/users"
                  onClick={() =>
                    this.handleAdminClick(
                      `Filter for candidates with a triage status of pending to approve or deny new candidates.`
                    )
                  }
                >
                  <MenuItem>
                    <Badge
                      badgeContent={
                        this.props.adminStats
                          ? this.props.adminStats.candidatesPendingCount
                          : ``
                      }
                    >
                      Pending Candidates
                    </Badge>
                  </MenuItem>
                </LinkContainer>
                <LinkContainer
                  to="/users"
                  onClick={() =>
                    this.handleAdminClick(
                      `Filter for candidates where their experience level is not specified to add experience levels to those candidates.`
                    )
                  }
                >
                  <MenuItem href="/users">
                    <Badge
                      badgeContent={
                        this.props.adminStats
                          ? this.props.adminStats
                            .candidatesWithoutExperienceLevelCount
                          : ``
                      }
                    >
                      Candidates Missing Experience Level
                    </Badge>
                  </MenuItem>
                </LinkContainer>
                <MenuItem href="/admin/locations/unassigned">
                  <Badge
                    badgeContent={
                      this.props.adminStats
                        ? this.props.adminStats.locationsUnassignedCount
                        : ``
                    }
                  >
                    Unassigned Locations
                  </Badge>
                </MenuItem>
                <MenuItem href="/admin/users">
                  All App Users
                </MenuItem>
                <MenuItem href="/admin/banner_announcements">
                  Banner Announcements
                </MenuItem>
              </NavDropdown>
            </Nav>
            <Nav pullRight>
              <NavItem className="admin-mode-button-container">
                <Button
                  displayType={this.props.adminMode ? `primary` : `default`}
                  onClick={this.toggleAdminMode}
                >
                  Admin Mode
                </Button>
              </NavItem>
            </Nav>
          </RequireAdmin>
        </Navbar.Collapse>
      </Navbar>
    );
  }
}

TopNavBar.propTypes = {
  pathname: PropTypes.string.isRequired,
  user: PropTypes.object.isRequired,
  adminMode: PropTypes.bool.isRequired,
  onboardingComplete: PropTypes.bool.isRequired,
  getAdminStats: PropTypes.func.isRequired,
  adminStats: PropTypes.object,
  config: PropTypes.object.isRequired,
  createMessage: PropTypes.func.isRequired,
  toggleAdminMode: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  return {
    pathname: state.router.location.pathname,
    adminStats: state.adminStats,
    user: state.user,
    adminMode: state.adminMode,
    onboardingComplete: onboardingComplete(state),
    config: state.config,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    createMessage: (payload) => dispatch(createMessage(payload)),
    toggleAdminMode: () => dispatch(toggleAdminMode()),
    getAdminStats: () => dispatch(getAdminStats()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(TopNavBar);
