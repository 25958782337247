import React from 'react';
import PropTypes from 'prop-types';
import { uniqBy } from 'lodash';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';

import { getSearchFilter } from 'selectors/selectors';
import { selectSearchFilter, deselectSearchFilter } from 'actions/actions';

import { ChevronDown, CheckboxIcon, CheckboxCheckedIcon } from 'components/Svg';

import 'stylesheets/application/search-filter.less';

const styles = {
  root: {
    'background-color': `blue`,
  },
  select: {
    'width': 200,
    'margin': `0px 10px 20px`,
  },
  selectLarge: {
    'width': 300,
  },
  label: {
    'font-size': 15,
  },
  listItemText: {
    'font-size': 13,
  },
};

class SearchFilter extends React.Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.setWrapperRef = this.setWrapperRef.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);

    this.state = {
      open: false,
    };
  }

  componentDidMount() {
    document.addEventListener(`mousedown`, this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener(`mousedown`, this.handleClickOutside);
  }

  setWrapperRef(node) {
    this.wrapperRef = node;
  }

  handleClickOutside(event) {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      this.setState({ open: false });
    }
  }

  handleChange(value) {
    const existingValues = this.getCurrentFilterValues();

    const filter = {
      field: this.props.field,
      fieldDisplayName: this.props.fieldDisplayName,
      value: value,
    };

    if (existingValues.includes(value)) {
      this.props.deselectSearchFilter(filter);
    } else {
      this.props.selectSearchFilter(filter);
    }
  }

  getFacetSearchItems() {
    const search = this.props.searches[this.props.searchType];

    if (!search) {
      return [];
    }

    const facetMap = search.facets[this.props.field];

    if (!facetMap) {
      return [];
    }

    return Object.keys(facetMap).map(value => {
      return {
        value,
        label: value,
        secondaryLabel: facetMap[value],
      };
    });
  }

  getCurrentFilterValues() {
    return this.props.searchFilters.filter(searchFilter => {
      return searchFilter.field == this.props.field;
    }).map(searchFilter => searchFilter.value);
  }

  getSelectedItems() {
    return this.getCurrentFilterValues().map(value => ({value: value, label: value, secondaryLabel: `` }));
  }

  getSortedItems() {
    const selectedItems = this.getSelectedItems();
    const facetSearchItems = this.getFacetSearchItems();
    const items = uniqBy([...selectedItems, ...facetSearchItems], (item) => item.value);

    if (this.props.sort) {
      return this.props.sort(items);
    }
    return items;
  }

  render() {
    const sortedItems = this.getSortedItems();

    return (
      <div className='search-filter'>
        <div className={`search-select search-select-${this.props.multiSelectSize}`} onClick={() => this.setState({ open: !this.state.open })}>
          {this.props.fieldDisplayName}
          <ChevronDown />
        </div>
        {this.state.open &&
          <div className='search-select-options' ref={this.setWrapperRef}>
            {sortedItems.map(item => {
              return (
                <div
                  key={item.value}
                  className={`search-select-option ${this.getCurrentFilterValues().includes(item.value) ? `selected` : `not-selected`}`}
                  onClick={() => this.handleChange(item.value)}
                >
                  <CheckboxCheckedIcon />
                  <CheckboxIcon />
                  <div className='select-label'>{item.label}</div>
                  <div className='select-secondary-label'>{item.secondaryLabel}</div>
                </div>
              );
            })}
          </div>
        }
      </div>
    );
  }
}

SearchFilter.propTypes = {
  classes: PropTypes.object.isRequired,
  field: PropTypes.string.isRequired,
  sort: PropTypes.func,
  fieldDisplayName: PropTypes.string.isRequired,
  searchType: PropTypes.string.isRequired,
  multiSelectSize: PropTypes.string,
  searches: PropTypes.object.isRequired,
  searchFilters: PropTypes.array.isRequired,
  selectSearchFilter: PropTypes.func.isRequired,
  deselectSearchFilter: PropTypes.func.isRequired,
  namespace: PropTypes.string.isRequired,
  dropdownSubtitleType: PropTypes.string.isRequired,
};

function mapStateToProps(state, ownProps) {
  return {
    searches: state.searches,
    searchFilters: getSearchFilter(state, ownProps),
  };
}

function mapDispatchToProps(dispatch, ownProps) {
  return {
    selectSearchFilter: (filter) => dispatch(selectSearchFilter(filter, ownProps.namespace)),
    deselectSearchFilter: (field) => dispatch(deselectSearchFilter(field, ownProps.namespace)),
  };
}

const SearchFilterWithState = connect(
  mapStateToProps,
  mapDispatchToProps
)(SearchFilter);

export default withStyles(styles)(SearchFilterWithState);
