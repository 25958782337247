import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import { withStyles } from '@material-ui/core/styles';
import StyledTextField from 'components/StyledTextField';
import Button from 'components/Button';
import {
  requiredFormValidator,
  linkedInFormValidator,
  limitSpecialCharactersValidator,
  emailValidator,
} from 'helpers/application';

const styles = {
  form: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  textField: {
    marginBottom: 15,
    width: '100%',
  },
  button: {
    marginTop: 20,
    width: '100%',
  },
};

const SignUpModalContents = ({ handleSubmit, errorMessage, classes }) => (
  <div className="signup auth-modal">
    <h3 className="welcome text-center">Welcome!</h3>
    <p className="help-text text-center">Enter your details below to create an account.</p>
    {errorMessage && <p className="error-message text-center">{errorMessage}</p>}
    <form onSubmit={handleSubmit} className={classes.form}>
      <Field
        name="first_name"
        component={StyledTextField}
        label="First Name"
        validate={[requiredFormValidator, limitSpecialCharactersValidator]}
        classes={classes}
      />
      <Field
        name="last_name"
        component={StyledTextField}
        label="Last Name"
        validate={[requiredFormValidator, limitSpecialCharactersValidator]}
        classes={classes}
      />
      <Field
        name="email"
        component={StyledTextField}
        label="Email"
        validate={[requiredFormValidator, emailValidator]}
        classes={classes}
      />
      <Field
        name="linkedin_url"
        component={StyledTextField}
        label="LinkedIn URL"
        validate={[requiredFormValidator, linkedInFormValidator]}
        classes={classes}
      />
      <Field
        name="sign_up_code"
        component={StyledTextField}
        label="Sign Up Code"
        validate={[requiredFormValidator]}
        classes={classes}
        type="password"
      />
      <Button
        displayType="primary"
        type="submit"
        className={classes.button}
      >
        Create Account
      </Button>
    </form>
  </div>
);

SignUpModalContents.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  errorMessage: PropTypes.string,
  classes: PropTypes.object.isRequired,
};

// Wrap with reduxForm and withStyles
export default reduxForm({
  form: 'signUpForm', // Unique identifier for this form
})(withStyles(styles)(SignUpModalContents));
