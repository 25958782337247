import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import MaterialModal from '@material-ui/core/Modal';

const styles = theme => ({
  root: {
    display: `flex`,
    alignItems: `center`,
    justifyContent: `center`,
  },
  paper: {
    outline: `none`,
    borderRadius: `4px`,
    maxHeight: `90%`,
    overflowY: `scroll`,
    position: `absolute`,
    width: theme.spacing.unit * 50,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing.unit * 4,
  },
  paperWide: {
    width: theme.spacing.unit * 100,
  },
});

class Modal extends React.Component {
  render() {
    const { classes, wide, children, ...props } = this.props;

    const paperClasses = wide ? [classes.paper, classes.paperWide].join(` `) : classes.paper;
    return (
      <MaterialModal
        classes={{root: classes.root}}
        {...props}
      >
        <div className={paperClasses}>
          {children}
        </div>
      </MaterialModal>
    );
  }
}

Modal.propTypes = {
  classes: PropTypes.object.isRequired,
  children: PropTypes.any.isRequired,
  wide: PropTypes.bool,
};

export default withStyles(styles)(Modal);
