import React from 'react';
import PropTypes from 'prop-types';

class ConditionalRender extends React.Component {
  render() {
    if (!this.props.shouldRender) {
      return null;
    }
    return this.props.children;
  }
}

ConditionalRender.propTypes = {
  children: PropTypes.any.isRequired,
  shouldRender: PropTypes.bool.isRequired,
};

export default ConditionalRender;
