import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { values } from 'lodash';

import { getCandidateProperties } from 'middleware/mixpanel';
import { createMessage } from 'actions/actions';
import { collapseCandidateDesiredCompanySizes, getAbbreviatedTimeAgo } from 'helpers/application';

import {
  CenterCard,
  CenterCardImage,
  CenterCardNotice,
  CenterCardFooter,
  CenterCardBody,
  CenterCardTitle,
  CenterCardSubtitle,
  CenterCardDetail,
  CenterCardContent,
  CenterCardFooterCenter,
  CenterCardTopLeft,
  CenterCardTopRight,
  CenterCardFooterDetail,
} from 'components/CenterCard';
import Popover from 'components/Popover';
import WithTrackAction from 'components/WithTrackAction';
import FeaturedCandidateStar from 'components/FeaturedCandidateStar';
import ContactCandidateModal from 'components/ContactCandidateModal';
import { LinkedinIcon } from 'components/Svg';
import WithResourceActions from 'components/WithResourceActions';
import Button from 'components/Button';
import RequireAdmin from 'components/RequireAdmin';
import AdminCandidateModal from 'components/AdminCandidateModal';
import LinkedGlyphiconWithTooltip from 'components/LinkedGlyphiconWithTooltip';

import UserIcon from 'images/user.svg';
import 'stylesheets/application/center-candidate-card.less';

class CenterCandidateCard extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      contactClicked: false,
    };

    this.createIntroRequest = this.createIntroRequest.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.handleContactClick = this.handleContactClick.bind(this);
    this.handleLinkedinClick = this.handleLinkedinClick.bind(this);
  }

  introRequestForCandidate() {
    return values(this.props.introRequests).find((introRequest) => {
      return introRequest.candidateId === this.props.candidate.objectID;
    });
  }

  closeModal() {
    this.setState({contactClicked: false});
  }

  handleLinkedinClick() {
    const request = {
      name: `Click External Link`,
      properties: {
        'Link': this.props.candidate.linkedin_url,
        'Link Type': `Candidate LinkedIn`,
        ...getCandidateProperties(this.props.candidate),
      },
    };

    this.props.track(request);
  }

  handleContactClick() {
    this.setState({contactClicked: true});
    this.props.createResource({
      url: `/candidate_views`,
      store: `candidateViews`,
      candidate: this.props.candidate,
      candidate_view: {
        hiring_manager_id: this.props.currentUser.id,
        candidate_id: this.props.candidate.objectID,
      },
    });
  }

  createIntroRequest({ jobTitle, jobUrl }) {
    this.props.createResource({
      url: `/intro_requests`,
      store: `introRequests`,
      candidate: this.props.candidate,
      intro_request: {
        hiring_manager_id: this.props.currentUser.id,
        candidate_id: this.props.candidate.objectID,
        job_title: jobTitle,
        job_url: jobUrl,
      },
    }).then((response) => {
      if (response) {
        this.props.createMessage({
          body: `Thanks for requesting an introduction! The First Round talent team will reach out if ${this.props.candidate.first_name} is interested in opportunities at ${this.props.currentUser.companyName}.`,
          dismissable: true,
          type: `success`,
          expires: false,
        });
      }
    }).catch(() => {
      this.props.createMessage({
        body: `Could not request an introduction. Someone from your company may have already requested an introduction with this candidate.`,
        dismissable: true,
        type: `error`,
        expires: true,
      });
    });

    this.setState({contactClicked: false});
  }

  getLookingIn() {
    if (this.props.candidate.region_names.length > 1) {
      return `Multiple Locations`;
    }

    return this.props.candidate.region_names[0];
  }


  getIndustries() {
    if (this.props.candidate.industry_names.length > 1) {
      return `Multiple Industries`;
    }

    return this.props.candidate.industry_names[0];
  }

  getPublicNote() {
    if (this.props.candidate.public_note) {
      let publicNote = this.props.candidate.public_note.split(`\n`);
      publicNote.map(function(line) {
        return line.trim();
      });
      return publicNote.join(`. `).trim();
    }
  }

  getShortenedBio() {
    if (this.props.candidate.bio) {
      let bio = this.props.candidate.bio.split(`\n`);
      bio.map(function(line) {
        return line.trim();
      });
      bio = bio.join(`. `).trim()
      return bio.length >=300 ? bio.slice?.(0, 300) + "..." : bio;
    }
  }

  getBio() {
    if (this.props.candidate.bio) {
      let bio = this.props.candidate.bio.split(`\n`);
      bio.map(function(line) {
        return line.trim();
      });
      return bio.join(`. `).trim()
    }
  }

  render() {
    const candidateFullName =`${this.props.candidate.first_name} ${this.props.candidate.last_name}`;
    const candidateResumeUrl = this.props.candidate.resume_url || this.props.candidate.secondary_resume_url;
    const imageSource = this.props.candidate.image_url ? this.props.candidate.image_url : UserIcon;
    const joinedAt = getAbbreviatedTimeAgo(this.props.candidate.last_sign_in_at_epoch);
    const lookingIn = this.getLookingIn();
    const industries = this.getIndustries();
    const introRequest = this.introRequestForCandidate();
    const publicNote = this.getPublicNote();
    const bio = this.getBio();
    const shortenedBio = this.getShortenedBio();
    const requestedByCompanyIds = this.props.candidate.candidate_intro_request_company_ids;
    const hasBeenRequestedByCompany = requestedByCompanyIds?.includes(this.props.currentUser.companyId)

    return (
      <CenterCard className='center-candidate-card'>
        <ContactCandidateModal
          candidate={this.props.candidate}
          closeModal={this.closeModal}
          open={this.state.contactClicked}
          createIntroRequest={this.createIntroRequest}
          currentUser={this.props.currentUser}
        />
        <RequireAdmin adminModeOnly>
          {this.props.candidate.salesforce_talent_engagement_id && (
            <CenterCardTopLeft withNotice={!!publicNote}>
              <Button
                tagType='externalLink'
                displayType='notice'
                href={`https://firstround.my.salesforce.com/${this.props.candidate.salesforce_talent_engagement_id}`}
                target='_blank'
                rel='noopener noreferrer'
              >
                View in Salesforce
              </Button>
            </CenterCardTopLeft>
          )}
          <CenterCardTopRight withNotice={!!publicNote}>
            <AdminCandidateModal candidate={this.props.candidate} />
          </CenterCardTopRight>
        </RequireAdmin>
        <CenterCardNotice>
          {publicNote}
        </CenterCardNotice>
        <CenterCardContent withNotice={!!publicNote}>
          <CenterCardBody>
            <div className='featured-candidate-star-container'>
              <FeaturedCandidateStar featured={this.props.candidate.featured} />
            </div>
            <CenterCardImage type='circle' src={imageSource} fallback={UserIcon} />
            <CenterCardTitle>
              <div className='candidate-title-container'>
                <div>{candidateFullName}</div>
                {candidateResumeUrl && (
                  <LinkedGlyphiconWithTooltip
                    url={candidateResumeUrl}
                    glyphiconKey={'new-window'}
                    tooltipText={'View the candidate\'s resume'}
                  />
                )}
              </div>
            </CenterCardTitle>
            <CenterCardSubtitle>
              {this.props.candidate.title} at <strong>{this.props.candidate.company_name}</strong>
            </CenterCardSubtitle>
            <Popover
              disablePopover={bio && bio.length < 300}
              parent={
                <CenterCardDetail
                  content={shortenedBio}
                  prefix='Bio'
                />
              }
              popover={
                <div>
                  {bio}
                </div>
              }
            />
            <CenterCardDetail
              content={this.props.candidate.primary_functional_area_name}
              prefix='Interested in'
            />
            <Popover
              disablePopover={this.props.candidate.region_names.length <= 1}
              parent={
                <CenterCardDetail
                  content={lookingIn}
                  prefix='Looking in'
                />
              }
              popover={
                <div>
                  {this.props.candidate.region_names.join(`, `)}
                </div>
              }
            />
            <CenterCardDetail
              content={this.props.candidate.experience_level_name}
              prefix='Experience'
            />
            <CenterCardDetail
              content={this.props.candidate.skill_names.join(`, `)}
              prefix='Skills'
            />
            <CenterCardDetail
              content={this.props.candidate.role_type_names.join(`, `)}
              prefix='Role Type(s)'
            />
            <Popover
              disablePopover={this.props.candidate.industry_names.length <= 1}
              parent={
                <CenterCardDetail
                  content={industries}
                  prefix='Industries'
                />
              }
              popover={
                <div>
                  {this.props.candidate.industry_names.join(`, `)}
                </div>
              }
            />
            <CenterCardDetail
              content={collapseCandidateDesiredCompanySizes(this.props.candidate.company_sizes)}
              prefix='Desired Company Size'
            />
            {this.props.candidate.requires_sponsorship && (
              <CenterCardDetail
                content='Requires Visa Sponsorship'
              />
            )}
          </CenterCardBody>
          <CenterCardFooter>
            <CenterCardFooterCenter>
              {!introRequest && !hasBeenRequestedByCompany &&
                <Button
                  displayType='primary'
                  onClick={this.handleContactClick}
                >
                  Contact
                </Button>
              }
              {!introRequest && hasBeenRequestedByCompany &&
                <Button disabled>
                  Requested
                </Button>
              }
              {!!introRequest &&
                <Button disabled>
                  Requested
                </Button>
              }
            </CenterCardFooterCenter>
            <CenterCardFooterDetail>
              Last login {joinedAt}
            </CenterCardFooterDetail>
            <CenterCardFooterDetail>
              {this.props.candidate.linkedin_url &&
                <a
                  href={this.props.candidate.linkedin_url}
                  onClick={this.handleLinkedinClick}
                  className='linkedin-link'
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  <span>View on LinkedIn</span>
                  <LinkedinIcon />
                </a>
              }
            </CenterCardFooterDetail>
          </CenterCardFooter>
        </CenterCardContent>
      </CenterCard>
    );
  }
}

CenterCandidateCard.propTypes = {
  currentUser: PropTypes.object.isRequired,
  candidate: PropTypes.object.isRequired,
  introRequests: PropTypes.object,
  createResource: PropTypes.func.isRequired,
  createMessage: PropTypes.func.isRequired,
  track: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  return {
    currentUser: state.user,
    introRequests: state.introRequests,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    createMessage: (payload) => dispatch(createMessage(payload)),
  };
}

const CenterCandidateCardWithState = connect(
  mapStateToProps,
  mapDispatchToProps
)(CenterCandidateCard);

export default WithTrackAction(WithResourceActions(CenterCandidateCardWithState));
