import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';

import WithUser from 'components/WithUser';

class HiringManagerRoute extends React.Component {

  isLoggedIn() {
    return !!this.props.user;
  }

  isHiringManager() {
    return this.props.user.hiringManager || this.props.user.admin;
  }


  render() {

    if (!this.isHiringManager() || !this.isLoggedIn()) {
      return <Redirect to='/welcome/' />;
    }

    return (
      <Route {...this.props}></Route>
    );
  }
}

HiringManagerRoute.propTypes = {
  user: PropTypes.object,
  config: PropTypes.object.isRequired,
  pathname: PropTypes.string.isRequired,
};

function mapStateToProps(state) {
  return {
    config: state.config,
    pathname: state.router.location.pathname,
  };
}

export default WithUser(connect(mapStateToProps)(HiringManagerRoute));
