import { createSelector } from 'reselect';

const getUser = state => state.user;
const getRegions = state => state.meta.regions;

const getNamespace = (state, props) => {
  if (!props.namespace) {
    throw `Attempting to fetch namespaced state without specifying a namespace`;
  }

  const namespace = state[props.namespace];

  if (!namespace) {
    throw `Attempting to fetch an unknown namespace. Received: ${props.namespace}`;
  }
  return namespace;
};

const getSearchFilter = createSelector(
  [getNamespace],
  (namespace) => namespace.searchFilters
);

const getSearchQuery = createSelector(
  [getNamespace],
  (namespace) => namespace.searchQuery
);

const getSearchQueryInput = createSelector(
  [getNamespace],
  (namespace) => namespace.searchQueryInput
);

const getSearchCurrentPage = createSelector(
  [getNamespace],
  (namespace) => namespace.searchCurrentPage
);

const onboardingComplete = createSelector(
  [getUser],
  (user) => {
    return !!user && (user.hiringManager || user.onboardingStatus === `complete`);
  }
);

const regionsForCandidates = createSelector(
  [getRegions],
  (regions) => regions.filter(region => !region.hide_from_candidates)
);

export {
  getSearchFilter,
  getSearchQueryInput,
  getSearchQuery,
  getSearchCurrentPage,
  onboardingComplete,
  regionsForCandidates,
};
