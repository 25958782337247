import React from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import MaterialBadge from '@material-ui/core/Badge';

const styles = theme => ({
  badge: {
    'top': `-2px`,
    'font-size': `10px`,
    'background-color': `#fc5c94`,
  },
  root: {
    'padding-right': `${theme.spacing.unit * 2}px`,
  },
});

class Badge extends React.Component {

  render() {
    const { classes, badgeContent, ...props } = this.props;

    // TODO: The Material badge should by default hide the
    // badge whenever `badgeContent` is zero. However, this
    // does not appear to be triggering.
    if (badgeContent === 0) {
      return (
        <React.Fragment>
          {this.props.children}
        </React.Fragment>
      );
    }

    return (
      <MaterialBadge
        badgeContent={badgeContent}
        color='secondary'
        classes={classes}
        {...props}
      >
        {this.props.children}
      </MaterialBadge>
    );
  }
}

Badge.propTypes = {
  badgeContent: PropTypes.any.isRequired,
  children: PropTypes.any.isRequired,
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Badge);
