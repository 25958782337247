import React from 'react';
import PropTypes from 'prop-types';

import { Link } from 'react-router-dom';

import 'stylesheets/application/internal-server-error-page.less';
import FirstRoundEngineeringImage from 'images/first_round_engineering.png';
import WithUser from 'components/WithUser';

class InternalServerErrorPage extends React.Component {
  render() {
    const redirectLink = this.props.user ? `/job-board` : `/welcome`;

    return (
      <div className='internal-server-error-page'>
        <div className='contents'>
          <img className='first-round-engineering-img' src={FirstRoundEngineeringImage} />
          <div className='text'>
            <h1>500 Internal Server Error</h1>
            <h4>
              <p>
                Your request could not be processed. Please try again and <a href='mailto:eng@firstround.com'>contact us</a> if this issue persists.
              </p>
              <p>
                <Link to={redirectLink}>
                  Return home.
                </Link>
              </p>
            </h4>
          </div>
        </div>
      </div>
    );
  }
}

InternalServerErrorPage.propTypes = {
  user: PropTypes.object,
};

export default WithUser(InternalServerErrorPage);
