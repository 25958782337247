import { snakeCase, reduce, isPlainObject } from 'lodash';
import { Headers } from 'node-fetch';

import { HTTP_NO_CONTENT } from 'constants/constants';
import { getCsrfToken } from 'helpers/dom';

const snakeCaseKeys = function(obj) {
  if (!isPlainObject(obj)) {
    return obj;
  } else if (Array.isArray(obj)) {
    return obj.map((value) => snakeCaseKeys(value));
  }
  return reduce(obj, (result, value, key) => {
    return { ...result, [snakeCase(key)]: snakeCaseKeys(value)};
  }, {});
};

const handleFetchResponse = function(response) {
  // Fetch does not throw HTTP errors as javascript errors.
  // Check for an error and throw if it exists.
  if (!response.ok) {
    const contentType = response.headers.get(`content-type`);
    if (contentType && contentType.includes(`application/json`)) {
      // If the API error returned JSON describing the error,
      // attempt to parse it
      return response.json().then(result => {
        throw {status: response.status, body: result};
      }, error => {
        // The response had a JSON content-type header but
        // did not return valid JSON
        throw {status: response.status, body: error};
      });
    } else {
      // The response did not return JSON
      throw {status: response.status, body: response.statusText};
    }
  } else if (response.status === HTTP_NO_CONTENT) {
    // Don't attempt to extract JSON when the HTTP status
    // indicates that no body is present.
    return Promise.resolve();
  } else {
    // Extract the successful response.
    return response.json();
  }
};

const fetchRequest = function(url, method, body) {
  const headers = new Headers({
    'Accept': `application/json`,
    'Content-Type': `application/json`,
    'X-CSRF-TOKEN': getCsrfToken(),
  });

  return fetch(url, {
    method: method,
    body: JSON.stringify(snakeCaseKeys(body)),
    headers: headers,
    credentials: `include`,
  }).then(response => handleFetchResponse(response));

};

const getRequest = function(url) {
  return fetchRequest(url, `GET`);
};

const postRequest = function(url, body) {
  return fetchRequest(url, `POST`, body);
};

const patchRequest = function(url, body) {
  return fetchRequest(url, `PATCH`, body);
};

const deleteRequest = function(url) {
  return fetchRequest(url, `DELETE`);
};

export {
  getRequest,
  postRequest,
  patchRequest,
  deleteRequest,
};
