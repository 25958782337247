import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { createMessage } from 'actions/actions';

import WithResourceActions from 'components/WithResourceActions';

function WithResource(WrappedComponent) {
  class WrappedWithResource extends React.Component {
    constructor(props) {
      super(props);

      this.updateResource = this.updateResource.bind(this);
    }

    componentDidMount() {
      this.loadResource();
    }

    getResourceUrl() {
      const urlStartPath = this.props.admin ? `/admin` : ``;
      return `${urlStartPath}/${this.props.resourceStoreKey}/${this.props.resourceId}`;
    }

    loadResource() {
      const request = {
        url: this.getResourceUrl(),
        store: this.props.resourceStoreKey,
      };
      this.props.getResource(request);
    }

    updateResource(values) {
      return this.props.updateResource({
        url: this.getResourceUrl(),
        store: this.props.resourceStoreKey,
        [this.props.resourceName]: values,
      }).then((result) => {
        if (result) {
          this.props.createMessage({
            body: `Saved changes to ${this.props.resourceName}! It may take a minute for these changes to be reflected in the app.`,
            dismissable: true,
            type: `success`,
            expires: true,
          });
        }
      });
    }

    render() {
      // TODO: Better handle loading in progress and loading failed states
      if (!this.props.resourceObject) {
        return null;
      }

      const newProps = {
        onSubmit: this.updateResource,
        [this.props.resourceName]: this.props.resourceObject,
      };
      const props = {...this.props, ...newProps};
      return <WrappedComponent
        {...props}
      />;
    }
  }

  WrappedWithResource.propTypes = {
    resourceId: PropTypes.string.isRequired,
    resourceObject: PropTypes.object,
    resourceName: PropTypes.string.isRequired,
    resourceStoreKey: PropTypes.string.isRequired,
    admin: PropTypes.bool,
    getResource: PropTypes.func.isRequired,
    updateResource: PropTypes.func.isRequired,
    createMessage: PropTypes.func.isRequired,
  };

  function mapStateToProps(state, ownProps) {
    return {
      resourceObject: state[ownProps.resourceStoreKey][ownProps.resourceId],
    };
  }

  function mapDispatchToProps(dispatch) {
    return {
      createMessage: (payload) => dispatch(createMessage(payload)),
    };
  }

  return connect(mapStateToProps, mapDispatchToProps)(WithResourceActions(WrappedWithResource));
}

export default WithResource;
