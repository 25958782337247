import React from 'react';
import PropTypes from 'prop-types';

import {
  CenterCardTitle,
  CenterCardSubtitle,
} from 'components/CenterCard';
import Modal from 'components/Modal';

import 'stylesheets/application/contact-candidate-modal.less';

class OpportunityReferralModal extends React.Component {
  render() {

    return (
      <Modal
        className='opportunity-referral-modal'
        open={this.props.open}
        onClose={this.props.closeModal}
      >
        <div className='flex flex-column flex-center'>
          <CenterCardTitle>
            Do you know someone thinking of starting a company or exploring a new startup idea?
          </CenterCardTitle>
          <CenterCardSubtitle>
            We&apos;re always excited to hear about early-stage builders. If someone you know is thinking about founding a company, you can refer them to First Round
            {` `}
            <a href="https://firstround.typeform.com/to/FXn5fync?utm_campaign=careers" target="_blank" rel="noreferrer">here</a>.
          </CenterCardSubtitle>
          <button onClick={this.props.closeModal}>Close</button>
        </div>
      </Modal>
    );
  }
}

OpportunityReferralModal.propTypes = {
  open: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
};

export default OpportunityReferralModal;
