import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { candidateDesiredCompanySizeSort, experienceLevelSort } from 'helpers/application';
import { clearSearch } from 'actions/actions';

import WithResourceActions from 'components/WithResourceActions';
import LoggedInPage from 'components/LoggedInPage';
import SearchResultsList from 'components/SearchResultsList';
import CenterCandidateCard from 'components/CenterCandidateCard';
import SearchQueryBar from 'components/SearchQueryBar';
import SearchFiltersBar from 'components/SearchFiltersBar';
import SearchGroup from 'components/SearchGroup';
import SearchPaginator from 'components/SearchPaginator';
import { alphabeticalSort } from '../helpers/application';

class CandidatesPage extends React.Component {
  componentDidMount() {
    this.loadIntroRequests();
  }

  componentWillUnmount() {
    this.props.clearSearch(`SEARCH_TYPE_CANDIDATES_LIST`);
  }

  loadIntroRequests() {
    const request = {
      url: `/intro_requests`,
      store: `introRequests`,
    };
    this.props.getResources(request);
  }

  render() {
    const facets = [
      {
        field: `region_names`,
        fieldDisplayName: `Location`,
      },
      {
        field: `primary_functional_area_name`,
        fieldDisplayName: `Role`,
      },
      {
        field: `experience_level_name`,
        fieldDisplayName: `Experience`,
        sort: (items) => experienceLevelSort(items),
      },
      {
        field: `company_sizes`,
        fieldDisplayName: `Desired Company Size`,
        sort: (items) => candidateDesiredCompanySizeSort(items),
      },
      {
        field: `role_type_names`,
        fieldDisplayName: `Role Types`,
        sort: (items) => alphabeticalSort(items),
      },
      {
        field: `industry_names`,
        fieldDisplayName: `Desired Industries`,
        sort: (items) => alphabeticalSort(items),
      },
      {
        field: `skill_names`,
        fieldDisplayName: `Skills`,
        sort: (items) => alphabeticalSort(items),
      },
    ];


    return (
      <div>
        <div className="pt-2 alert alert-info alert-frc">
          If you&apos;re interested in connecting with a candidate, please request an introduction through First Round Careers by clicking the &apos;Contact&apos; button, rather than reaching out to the candidate directly. This ensures a seamless candidate experience and enables us to get the double opt-in.
        </div>
        <SearchGroup>
          <SearchQueryBar
            namespace='candidatesPage'
            placeholder='Search by name, company, title, skills...'
          />
          <SearchFiltersBar
            model='User'
            userType='hiringManager'
            searchType='SEARCH_TYPE_CANDIDATES_LIST_ALL_CANDIDATES'
            namespace='candidatesPage'
            facets={facets}
            dropdownSubtitleType='candidates'
          />
        </SearchGroup>
        <SearchResultsList
          model='User'
          userType='hiringManager'
          searchType='SEARCH_TYPE_CANDIDATES_LIST'
          countType='Candidates'
          resultComponentCreator={(candidate) => (
            <CenterCandidateCard candidate={candidate} key={candidate.objectID} />
          )}
          namespace='candidatesPage'
          twoColumnList
        />
        <SearchPaginator
          searchType='SEARCH_TYPE_CANDIDATES_LIST'
          namespace='candidatesPage'
        />
      </div>
    );
  }
}

CandidatesPage.propTypes = {
  getResources: PropTypes.func.isRequired,
  clearSearch: PropTypes.func.isRequired,
};

function mapDispatchToProps(dispatch) {
  return {
    clearSearch: searchType => dispatch(clearSearch(searchType)),
  };
}

const CandidatesPageWithState = connect(
  null,
  mapDispatchToProps
)(CandidatesPage);

export default WithResourceActions(LoggedInPage(CandidatesPageWithState));
