import React from 'react';
import PropTypes from 'prop-types';

import MenuItem from '@material-ui/core/MenuItem';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import TextField from '@material-ui/core/TextField';

class MultiSelect extends React.Component {
  constructor(props) {
    super(props);

    this.renderMenuItem = this.renderMenuItem.bind(this);
    this.renderValue = this.renderValue.bind(this);
  }

  renderMenuItem(item, ListItemTextProps) {
    const value = this.props.value;

    const checked = value.includes(item.value);
    return (
      <MenuItem key={item.value} value={item.value}>
        <Checkbox checked={checked} />
        <ListItemText
          primary={item.label}
          secondary={item.secondaryLabel}
          {...ListItemTextProps}
        />
      </MenuItem>
    );
  }

  renderValue(selected) {
    return this.props.items.filter(item => selected.includes(item.value)).
      map(item => item.label).join(`, `);
  }

  getSortedItems(sort) {
    if (sort) {
      return sort(this.props.items);
    }
    return this.props.items;
  }

  render() {
    const { ListItemTextProps, sort, ...props } = this.props;
    const sortedItems = this.getSortedItems(sort);
    // The material-ui TextField component comes with InputLabel and HelperText
    // components and allows us to specify to use Select as the Input component.
    return (
      <TextField
        select={true}
        SelectProps={{
          multiple: true,
          renderValue: this.renderValue,
        }}
        {...props}
      >
        {sortedItems.slice(0, 100).map(item => this.renderMenuItem(item, ListItemTextProps))}
      </TextField>
    );
  }
}

MultiSelect.propTypes = {
  items: PropTypes.array.isRequired,
  value: PropTypes.array.isRequired,
  ListItemTextProps: PropTypes.object,
  onChange: PropTypes.func,
  sort: PropTypes.func,
};

export default MultiSelect;
