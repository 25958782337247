import React from 'react';
import PropTypes from 'prop-types';

import WithUser from 'components/WithUser';
import WithLocationActions from 'components/WithLocationActions';
import HardRedirect from 'components/HardRedirect';

function LoggedOutPage(Page) {
  class WrappedPage extends React.Component {
    render() {
      if (this.props.user) {
        // Hard redirect yields a GET to the server root,
        // allowing the server to serve the proper "home" page for this user.
        return <HardRedirect to='/' />;
      }
      return <Page {...this.props} />;
    }
  }
  WrappedPage.propTypes = {
    user: PropTypes.object,
  };
  return WithUser(WithLocationActions(WrappedPage));
}

export default LoggedOutPage;
