import React from 'react';
import PropTypes from 'prop-types';

import WithUser from 'components/WithUser';
import WithLocationActions from 'components/WithLocationActions';
import HardRedirect from 'components/HardRedirect';

function LoggedInPage(Page) {
  class WrappedPage extends React.Component {
    render() {
      if (!this.props.user) {
        return <HardRedirect to='/welcome' />;
      }
      return <Page {...this.props} />;
    }
  }
  WrappedPage.propTypes = {
    user: PropTypes.object,
  };
  return WithUser(WithLocationActions(WrappedPage));
}

export default LoggedInPage;
