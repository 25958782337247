import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import queryString from 'query-string';
import { signUp } from 'actions/actions';

class HiringManagerSignUpPage extends React.Component {
  componentDidMount() {
    const params = queryString.parse(location.search);

    const request = {
      redirectPath: params.redirect_path,
    };

    this.props.signUp(request);
  }

  render() {
    // This component just needs to fire off hooks
    // to log in the hiring manager upon page load.
    return null;
  }
}

HiringManagerSignUpPage.propTypes = {
  signUp: PropTypes.func.isRequired,
};

function mapDispatchToProps(dispatch) {
  return {
    signUp: (request) => dispatch(signUp(request)),
  };
}

const HiringManagerSignUpPageWithState = connect(
  null,
  mapDispatchToProps
)(HiringManagerSignUpPage);

export default HiringManagerSignUpPageWithState;
