import React from 'react';
import PropTypes from 'prop-types';
import { FormControl } from 'react-bootstrap';
import { debounce } from 'lodash';
import { connect } from 'react-redux';

import { setSearchQuery, setSearchQueryInput, clear } from 'actions/actions';
import { getSearchQuery, getSearchQueryInput } from 'selectors/selectors';
import { Glyphicon } from 'react-bootstrap';

import 'stylesheets/application/search-query-bar.less';

/**
 * This component displays a form input for the user to search
 * and manages the interaction with Redux state. The input is
 * stored twice in the Redux store:
 *
 * 1. searchQueryInput, which updates immediately and reflects
 *    the value within the text input field.
 * 2. searchQuery, which updates after 200ms of debounce, to
 *    keep from spamming search requests.
 */

class SearchQueryBar extends React.Component {
  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
    this.handleChangeDebounced = debounce(props.setSearchQuery, 200);
  }

  componentWillUnmount() {
    this.props.clear(`SEARCH_QUERY`);
    this.props.clear(`SEARCH_QUERY_INPUT`);
  }

  handleChange(e) {
    const value = e.target.value;
    this.props.setSearchQueryInput(value);
    this.handleChangeDebounced(value);
  }

  render() {
    return (
      <div className='search-bar'>
        <FormControl
          type='text'
          value={this.props.searchQueryInput}
          placeholder={this.props.placeholder}
          onChange={this.handleChange}
          bsClass='search-bar-input'
          focus='true'
        />
        <Glyphicon className='search-bar-icon' glyph="search" />
      </div>
    );
  }
}

SearchQueryBar.propTypes = {
  searchQuery: PropTypes.string,
  searchQueryInput: PropTypes.string,
  setSearchQuery: PropTypes.func.isRequired,
  setSearchQueryInput: PropTypes.func.isRequired,
  namespace: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  clear: PropTypes.func.isRequired,
};

function mapStateToProps(state, ownProps) {
  return {
    searchQueryInput: getSearchQueryInput(state, ownProps),
    searchQuery: getSearchQuery(state, ownProps),
  };
}

function mapDispatchToProps(dispatch, ownProps) {
  return {
    setSearchQuery: query => dispatch(setSearchQuery(query, ownProps.namespace)),
    setSearchQueryInput: query => dispatch(setSearchQueryInput(query, ownProps.namespace)),
    clear: clearType => dispatch(clear(clearType, ownProps.namespace)),
  };
}

const SearchQueryBarWithState = connect(
  mapStateToProps,
  mapDispatchToProps
)(SearchQueryBar);

export default SearchQueryBarWithState;
