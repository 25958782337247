import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { withProps, withState } from 'recompose';
import { reduxForm, formValueSelector } from 'redux-form';
import MenuItem from '@material-ui/core/MenuItem';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';

import { createMessage, userReceived } from 'actions/actions';

import WithResourceActions from 'components/WithResourceActions';
import WithUser from 'components/WithUser';
import StyledTextField from 'components/StyledTextField';
import Button from 'components/Button';
import Modal from 'components/Modal';

import 'stylesheets/application/offboarding-followup-modal.less';

const OFFBOARDING_REASONS = {
  hired_by_community: `I found a new role within the First Round community.`,
  hired_outside_community: `I found a new role outside of the First Round community.`,
  pausing_search: `I'm pausing my job search.`,
  other: `Other`,
};

const styles = {
  label: {
    'font-size': 15,
    '@media screen and (max-width: 700px)': {
      'font-size': 12,
    },
  },
};

/**
 * Shows a small follow-up form asking why a candidate has elected
 * to stop sharing their profile on Careers. Candidates see this
 * after replying "No, I'm not looking" to the offboarding email.
 *
 * Our goal here is to collect some additional context on their
 * reason for leaving, which will be recorded on `user.offboarding_reason`.
 */

class OffboardingFollowupModal extends React.Component {
  componentDidMount() {
    if (this.shouldShowOffboardingFollowupModal()) {
      this.props.setShowModal(true);
    }
  }

  shouldShowOffboardingFollowupModal() {
    return (
      this.props.user &&
      this.props.user.offboarded &&
      !this.props.user.offboardingReason
    );
  }

  render() {
    const {
      classes,
      offboarding_reason,
      handleSubmit,
      showModal,
      setShowModal,
    } = this.props;

    return (
      <Modal
        onClose={() => setShowModal(false)}
        open={showModal}
      >
        <div className="offboarding-followup-modal-content">
          <p>Your profile has been removed from the First Round Careers portal! You{`'`}ll still be able to view open roles at First Round companies, but companies won{`'`}t be able to view your profile.</p>
          <p>If you{`'`}re open to sharing, we{`'`}d love to hear more about why you{`'`}re no longer looking for new roles.</p>
          <form onSubmit={handleSubmit}>
            <StyledTextField
              name='offboarding_reason'
              label='Reason for leaving'
              classes={classes}
              select={true}
            >
              {Object.keys(OFFBOARDING_REASONS).map((offboardingReason) => (
                <MenuItem
                  key={offboardingReason}
                  value={offboardingReason}
                  className={classes.label}
                >
                  {OFFBOARDING_REASONS[offboardingReason]}
                </MenuItem>
              ))}
            </StyledTextField>
            {(offboarding_reason === `other`) && (
              <StyledTextField
                name='offboarding_reason_other'
                label='Anything else you can share?'
                classes={classes}
              />
            )}
            <div className="submit">
              <Button
                displayType="primary"
                type="submit"
              >
                  Submit
              </Button>
            </div>
          </form>
        </div>
      </Modal>
    );
  }
}

OffboardingFollowupModal.propTypes = {
  updateResource: PropTypes.func,
  classes: PropTypes.object.isRequired,
  offboarding_reason: PropTypes.string,
  handleSubmit: PropTypes.func.isRequired,
  user: PropTypes.object,
  setShowModal: PropTypes.func.isRequired,
  showModal: PropTypes.bool.isRequired,
};

const offboardingFormSelector = formValueSelector(`offboarding`);

const withRedux = connect(
  state => ({
    offboarding_reason: offboardingFormSelector(state, `offboarding_reason`),
  }),
  dispatch => ({
    createMessage: (payload) => dispatch(createMessage(payload)),
    userReceived: (user) => dispatch(userReceived(user)),
  })
);

// Handles submission of the offboarding form. Takes the form values and propogates them to
// an `updateResource` call on this user's record. This form is optional. If users submit
// without selecting an offboarding reason, then the generic "no_longer_available" reason is
// automatically applied. Successful submission should close the modal and let the user know
// that their response has been recorded.
const onSubmit = props => formValues => {
  if (!formValues.offboarding_reason) {
    formValues[`offboarding_reason`] = `no_longer_available`;
  }
  return props.updateResource({
    url: `/users/${props.user.id}`,
    store: `users`,
    user: {
      ...formValues,
      status: formValues.offboarding_reason === `hired_by_community` ? `hired_by_community` : `no_longer_available`,
    },
  }).then((user) => {
    props.userReceived(user);
    props.setShowModal(false);
    props.createMessage({
      body: `Thanks for letting us know!`,
      dismissable: true,
      type: `success`,
      expires: false,
    });
  });
};

export default compose(
  WithUser,
  WithResourceActions,
  withRedux,
  withState(`showModal`, `setShowModal`, false),
  withProps(props => ({ onSubmit: onSubmit(props) })),
  reduxForm({ form: `offboarding` }),
  withStyles(styles)
)(OffboardingFollowupModal);
