import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { deselectSearchFilter, clear } from 'actions/actions';

import { CloseIcon } from 'components/Svg';

import 'stylesheets/application/active-filter-button.less';

class ActiveFilterButton extends React.Component {
  constructor(props) {
    super(props);

    this.deselectSearchFilter = this.deselectSearchFilter.bind(this);
  }

  isClearAllFilter() {
    return this.props.filter.field === `ALL`;
  }

  deselectSearchFilter() {
    if (this.isClearAllFilter()) {
      this.props.clear(`SEARCH_FILTERS`);
    }

    this.props.deselectSearchFilter(this.props.filter);
  }

  render() {
    const className = this.isClearAllFilter() ? `filter-button clear-all` : `filter-button`;
    return (
      <div onClick={this.deselectSearchFilter} className={className}>
        <CloseIcon />
        <span>{this.props.filter.value}</span>
      </div>
    );
  }
}

ActiveFilterButton.propTypes = {
  filter: PropTypes.object.isRequired,
  deselectSearchFilter: PropTypes.func.isRequired,
  clear: PropTypes.func.isRequired,
};

function mapDispatchToProps(dispatch, ownProps) {
  return {
    clear: clearType => dispatch(clear(clearType, ownProps.namespace)),
    deselectSearchFilter: (filter) => dispatch(deselectSearchFilter(filter, ownProps.namespace)),
  };
}

const ActiveFilterButtonWithState = connect(
  null,
  mapDispatchToProps
)(ActiveFilterButton);

export default ActiveFilterButtonWithState;
