const eventMap = {
  'SET_SEARCH_QUERY': searchEvent,
  'SELECT_SEARCH_FILTER': selectFilterEvent,
  'DESELECT_SEARCH_FILTER': deselectFilterEvent,
  'LOG_IN_SUCCESS': logInEvent,
  'SIGN_UP_SUCCESS': signUpEvent,
  'TRACK': trackEvent,
  'CREATE_RESOURCE_SUCCESS': createResourceEvent,
  '@@router/LOCATION_CHANGE': viewPageEvent,
};

// TODO: see if we can structure this in a way that's less
// likely to fall out of sync with our routes.
const pageNameMatchers = [
  { matcher: `/job-board`, pageName: `Job Board` },
  { matcher: `/users`, pageName: `Candidates Board` },
  { matcher: `/welcome`, pageName: `Welcome` },
  { matcher: `/info`, pageName: `User Info` },
  { matcher: `/internal-server-error`, pageName: `Internal Server Error`},
];

const mixpanelMiddleware = store => next => action => {
  // Make both the previous and next state available for
  // mixpanel to draw information from.
  const state = store.getState();
  const result = next(action);
  const nextState = store.getState();

  // These properties will get tacked on to every event.
  mixpanel.register(getSuperProperties(nextState));

  // set the user as last active now
  mixpanel.people.set(`Last Active Date`, new Date().toISOString());

  // If the user is logged in upon the initial page load,
  // send general session information to mixpanel.
  if (action.type === `USER_RECEIVED`) {
    const user = action.response;
    const peopleProperties = getPeopleProperties(user, nextState);
    mixpanel.identify(user.id);
    mixpanel.people.set(peopleProperties);
  }

  if (action.type === `SIGN_UP_SUCCESS`) {
    const user = action.response;
    const peopleProperties = getPeopleProperties(user, nextState);
    mixpanel.alias(user.id);
    mixpanel.people.set(peopleProperties);
  }

  // Get tracking information that is specific to kind of action.
  const event = getEvent(action, state, nextState);

  // Not all actions should get tracked. Further, some kinds of actions
  // should only get tracked under particular conditions (like searching).
  if (!event || event.skip) {
    return result;
  }

  mixpanelTrack(event);

  // mixpanel.identify flushes any people properties in queue.
  // This will create people profiles for our logged out users as well.
  // That way we can count sessions from the very beginning of a user's life.
  // https://help.mixpanel.com/hc/en-us/articles/115004723646-Create-your-first-profile#create-a-profile
  mixpanel.identify();

  return result;
};

// mixpanel.get_property is not available until Mixpanel
// is fully loaded, so the only option is to call it in
// the "loaded" callback of the init function or to set
// timeout and try again until Mixpanel loads
function waitForMixpanel(fn) {
  if (mixpanel.__loaded) {
    fn();
  } else {
    setTimeout(function() {
      waitForMixpanel(fn);
    }, 10);
  }
}

function incrementSuperProperty(countPropertyName) {
  const existingValue = mixpanel.get_property(countPropertyName) || 0;
  const incrementedValue = existingValue + 1;

  mixpanel.register({
    [countPropertyName]: incrementedValue,
  });
}

function mixpanelTrack(event) {
  var date = new Date().toISOString();
  if (event.shouldTrackCount) {
    var countPropertyName = `${event.name} Count`;
    // Increment the super property.
    // This overwrites the previous super property value.
    waitForMixpanel(incrementSuperProperty.bind(this, countPropertyName));
    // Increment the event property.
    // This sets a value on an event property (no overwriting)
    mixpanel.people.increment(countPropertyName, 1);
  }
  if (event.shouldTrackDates) {
    // set_once will set the property only if it doesn`t already exist
    mixpanel.people.set_once(`First ` + event.name + ` Date`, date);
    mixpanel.people.set(`Last ` + event.name + ` Date`, date);
  }
  mixpanel.people.set(`Last Active Date`, date);
  mixpanel.track(event.name, event.properties);
}

function getEvent(action, state, nextState) {
  const eventResolver = eventMap[action.type];

  if (!eventResolver) {
    return;
  }

  return eventResolver(action, state, nextState);
}

function searchEvent(action) {
  return {
    name: `Search`,
    properties: {
      'Search Input': action.query,
    },
    shouldTrackCount: true,
  };
}

function selectFilterEvent(action) {
  return {
    name: `Set Filter`,
    properties: {
      'Filter Field': action.filter.fieldDisplayName,
      'Filter Values': action.filter.values,
    },
  };
}

function deselectFilterEvent(action) {
  return {
    name: `Clear Filter`,
    properties: {
      'Filter Field': action.field,
    },
  };
}

function logInEvent() {
  return {
    name: `Log In`,
    properties: {},
    shouldTrackCount: true,
    shouldTrackDates: true,
  };
}

function signUpEvent() {
  return {
    name: `Sign Up`,
    properties: {},
    shouldTrackDates: true,
  };
}

function viewPageEvent() {
  return {
    name: `View Page`,
    properties: {},
    shouldTrackCount: true,
  };
}

function trackEvent(action) {
  return action.request;
}

function createResourceEvent(action) {
  switch (action.request.store) {
    case `introRequests`:
      return {
        name: `Request Intro`,
        properties: getCandidateProperties(action.request.candidate),
        shouldTrackCount: true,
        shouldTrackDates: true,
      };
    case `candidateViews`:
      return {
        name: `View Candidate`,
        properties: getCandidateProperties(action.request.candidate),
        shouldTrackCount: true,
        shouldTrackDates: true,
      };
  }
}

function getCandidateProperties(candidate) {
  return {
    'Candidate Name': `${candidate.first_name} ${candidate.last_name}`,
    'Candidate ID': candidate.objectID,
    'Candidate Email': candidate.email,
    'Candidate Company': candidate.company_name,
    'Candidate Title': candidate.title,
    'Candidate Referral Medium': candidate.referral_medium,
    'Candidate is Featured': candidate.featured,
    'Candidate Days Since Sign Up': Math.ceil((Date.now() - candidate.created_at_epoch * 1000) / (24*60*60*1000)),
  };
}

function getPeopleProperties(user, nextState) {
  const userRegions = user.regionIds.map(regionId => {
    return nextState.meta.regions.find(region => region.id === regionId).name;
  });

  const primaryFunctionalArea = nextState.meta.functionalAreas.find(functionalArea => {
    return functionalArea.id === user.primaryFunctionalAreaId;
  });

  const functionalAreas = user.functionalAreaIds.map(functionalAreaId => {
    return nextState.meta.functionalAreas.find(functionalArea => functionalArea.id === functionalAreaId).name;
  });

  return {
    '$name': `${user.firstName} ${user.lastName}`,
    '$email': user.email,
    'Sign Up Date': user.createdAt,
    'Admin User': user.admin,
    'User ID': user.id,
    'Last Active Date': new Date().toISOString(),
    'Title': user.title,
    'Company': user.companyName,
    'Referral Source': user.referralSource,
    'Entry Password': user.entryPasswordUsed,
    'Referral Medium': user.referralMedium,
    'Sharing Allowed': user.sharingAllowed,
    'When Looking': user.whenLooking,
    'LinkedIn URL': user.linkedinUrl,
    'LinkedIn Headline': user.linkedinHeadline,
    'Primary Functional Area': primaryFunctionalArea ? primaryFunctionalArea.name : ``,
    'Functional Areas': functionalAreas,
    'Regions': userRegions,
    'Is Hiring Manager': user.hiringManager,
    'Is Candidate': user.candidate,
  };
}

function getSuperProperties(nextState) {
  return {
    'Page Name': getPageName(nextState),
    'Mobile': nextState.config.isMobile,
  };
}

function getPageName(nextState) {
  const pathname = nextState.router.location.pathname;

  const pageNameMatcher = pageNameMatchers.find((entry) => {
    return pathname.startsWith(entry.matcher);
  });

  if (!pageNameMatcher) {
    return null;
  }

  return pageNameMatcher.pageName;
}

export {
  mixpanelMiddleware,
  getCandidateProperties,
};
