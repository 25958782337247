import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { withState } from 'recompose';

import WithUser from 'components/WithUser';
import Button from 'components/Button';
import Modal from 'components/Modal';

import 'stylesheets/application/offboarding-modal.less';

/**
 * Asks the candidate if they are still actively looking for a job. This modal
 * displays to candidates who received the Offboarding Email but have not
 * responded to it yet. Clicking the "Yes" and "No" buttons here take equivalent
 * actions to clicking the "Yes" and "No" links from the Offboarding Email.
 *
 * The intent is to capture responses from candidates who end up on Careers
 * directly during offboarding, but haven't clicked one of the email links.
 */

class OffboardingModal extends React.Component {
  componentDidMount() {
    if (this.shouldShowOffboardingModal()) {
      this.props.setShowModal(true);
    }
  }

  shouldShowOffboardingModal() {
    return this.props.user && this.props.user.offboardingInProgress;
  }

  render() {
    const { showModal, setShowModal } = this.props;

    return (
      <Modal
        onClose={() => setShowModal(false)}
        open={showModal}
      >
        <div className="offboarding-modal">
          <p>We hope you{`'`}ve had the chance to explore opportunities on First Round Careers. Your profile is still being shared with First Round hiring managers, and we noticed it{`'`}s been a while since you last signed in.</p>
          <p>Are you still open to hearing about new roles within <a href="https://firstround.com/companies" target="_blank" rel="noopener noreferrer">our community</a>?</p>
          <Button
            tagType='externalLink'
            displayType='primary'
            href="/users/still_looking"
          >
              Yes, I{`'`}m still looking
          </Button>
          <Button
            tagType='externalLink'
            displayType='warning'
            href="/users/remove_me"
          >
              No, please remove me
          </Button>
        </div>
      </Modal>
    );
  }
}

OffboardingModal.propTypes = {
  user: PropTypes.object,
  setShowModal: PropTypes.func.isRequired,
  showModal: PropTypes.bool.isRequired,
};

export default compose(
  WithUser,
  withState(`showModal`, `setShowModal`, false)
)(OffboardingModal);
