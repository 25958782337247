import React from 'react';
import PropTypes from 'prop-types';
import { compact } from 'lodash';

import 'stylesheets/application/search-group.less';

class SearchGroup extends React.Component {
  render() {
    const className = compact([`search-group`, this.props.className]).join(` `);
    const props = {
      ...this.props,
      className,
    };

    return (
      <div {...props}>
        {this.props.children}
      </div>
    );
  }
}

SearchGroup.propTypes = {
  children: PropTypes.any.isRequired,
  className: PropTypes.string,
};

export default SearchGroup;
