import React from 'react';
import PropTypes from 'prop-types';

import { clear } from 'actions/actions';
import { connect } from 'react-redux';

import SearchFilter from 'components/SearchFilter';
import WithSearch from 'components/WithSearch';
import ActiveFilterButton from 'components/ActiveFilterButton';

import 'stylesheets/application/search-filters-bar.less';

class SearchFiltersBar extends React.Component {
  componentDidMount() {
    // This search lets us determine the facets to show as
    // multi-select dropdowns. This is somewhat duplicative
    // of other searches, but nicely enforces a separation
    // of concerns between filtering and displaying results.
    this.searchForFacets(this.props);
  }

  componentWillUnmount() {
    this.props.clear(`SEARCH_FILTERS`);
  }

  searchForFacets(props) {
    const facets = props.facets.map(facet => facet.field);
    const filters = props.parentFilters || [];

    const request = {
      params: {
        filters,
        facets,
      },
      query: ``,
      userType: props.userType,
      model: props.model,
      searchType: props.searchType,
    };

    this.props.search(request);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.adminMode !== this.props.adminMode) {
      this.searchForFacets(nextProps);
    }
  }

  render() {
    return (
      <div>
        <div className='search-filters-bar'>
          {this.props.facets.map(facet => {
            return (
              <SearchFilter
                key={facet.field}
                field={facet.field}
                fieldDisplayName={facet.fieldDisplayName}
                searchType={this.props.searchType}
                namespace={this.props.namespace}
                dropdownSubtitleType={this.props.dropdownSubtitleType}
                multiSelectSize={this.props.facets.length < 5 ? `large` : `small` }
                sort={facet.sort}
              />
            );
          })}
        </div>
        <div className='search-filters-buttons'>
          {Object.values(this.props.searchFilters).length > 0 &&
            <ActiveFilterButton
              key={`ALL`}
              filter={{field: `ALL`, value: `Clear All Filters`}}
              namespace={this.props.namespace}
            />
          }
          {this.props.searchFilters.map(filter => {
            return (
              <ActiveFilterButton
                key={filter.value}
                filter={filter}
                namespace={this.props.namespace}
              />
            );
          })}
        </div>
      </div>
    );
  }
}

SearchFiltersBar.propTypes = {
  search: PropTypes.func.isRequired,
  facets: PropTypes.array.isRequired,
  model: PropTypes.string.isRequired,
  userType: PropTypes.string.isRequired,
  adminMode: PropTypes.bool.isRequired,
  searchType: PropTypes.string.isRequired,
  namespace: PropTypes.string.isRequired,
  parentFilters: PropTypes.array,
  clear: PropTypes.func.isRequired,
  dropdownSubtitleType: PropTypes.string.isRequired,
  searchFilters: PropTypes.array,
};


function mapDispatchToProps(dispatch, ownProps) {
  return {
    clear: clearType => dispatch(clear(clearType, ownProps.namespace)),
  };
}

function mapStateToProps(state, ownProps) {
  return {
    searchFilters: state[ownProps.namespace].searchFilters,
    adminMode: state.adminMode,
  };
}

const SearchFiltersBarWithState = connect(
  mapStateToProps,
  mapDispatchToProps
)(SearchFiltersBar);

export default WithSearch(SearchFiltersBarWithState);
