import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import { withStyles } from '@material-ui/core/styles';
import StyledTextField from 'components/StyledTextField';
import Button from 'components/Button';
import {
  requiredFormValidator,
  emailValidator,
} from 'helpers/application';

const styles = () => ({
  textField: {
    'margin-bottom': 15,
  },
  form: {
    'align-items': `center`,
    'margin-bottom': 100,
  },
  button: {
    'margin-top': 20,
    'width': `100%`,
    'justify-self': `center`,
  },
});

class MagicLinkModalContents extends React.Component {
  render() {
    const { handleSubmit, submitting, classes } = this.props;

    return (
      <div>
        <h3 className='welcome text-center'>
          Welcome Back!
        </h3>
        <p className="help-text text-center">
          Please enter your email address to receive a link to sign in.
        </p>
        <form onSubmit={handleSubmit} className='magic-link-form'>
          <Field
            name="email"
            component={StyledTextField}
            label="Email"
            validate={[requiredFormValidator, emailValidator]}
            classes={classes}
          />
          <Button
            displayType={submitting ? `default` : `primary`}
            type='submit'
            disabled={submitting}
            className={classes.button}
          >
            Send me a login link
          </Button>
        </form>
      </div>
    );
  }
}

MagicLinkModalContents.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  classes: PropTypes.object.isRequired,
  closeModal: PropTypes.func.isRequired,
};

export default reduxForm({
  form: `magicLink`, // a unique identifier for this form
})(withStyles(styles)(MagicLinkModalContents));
