import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { push, replace, go, goBack, goForward } from 'connected-react-router';

function WithLocationActions(WrappedComponent) {
  class WrappedWithLocationActions extends React.Component {

    render() {
      return <WrappedComponent
        {...this.props}
        push={this.props.push}
      />;
    }
  }

  WrappedWithLocationActions.propTypes = {
    push: PropTypes.func.isRequired,
    replace: PropTypes.func.isRequired,
    go: PropTypes.func.isRequired,
    goBack: PropTypes.func.isRequired,
    goForward: PropTypes.func.isRequired,
  };

  function mapDispatchToProps(dispatch) {
    return {
      push: (to) => dispatch(push(to)),
      replace: (to) => dispatch(replace(to)),
      go: (value) => dispatch(go(value)),
      goBack: () => dispatch(goBack()),
      goForward: () => dispatch(goForward()),
    };
  }

  return connect(null, mapDispatchToProps)(WrappedWithLocationActions);
}

export default WithLocationActions;
