import React from 'react';
import PropTypes from 'prop-types';

import { NotesIcon } from 'components/Svg';
import { compact } from 'lodash';

import 'stylesheets/application/center-card.less';

class CenterCard extends React.Component {
  render() {
    const className = compact([`center-card`, this.props.className]).join(` `);

    return (
      <div className={className}>
        {this.props.children}
      </div>
    );
  }
}

CenterCard.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string,
};

class CenterCardImage extends React.Component {
  render() {
    return (
      <div className='center-card-image-container'>
        <img
          className={`center-card-image center-card-image-${this.props.type}`}
          align='middle'
          src={this.props.src}
          onError={(error) => error.target.src = this.props.fallback}
        />
      </div>
    );
  }
}

CenterCardImage.propTypes = {
  src: PropTypes.any.isRequired,
  type: PropTypes.string.isRequired,
  className: PropTypes.string,
  fallback: PropTypes.any,
};

class CenterCardNotice extends React.Component {
  render() {
    if (!this.props.children) {
      return (
        <div className='center-card-notice-container'></div>
      );
    }

    return (
      <div className='center-card-notice-container'>
        <div className='center-card-notice'>
          <NotesIcon />
          <p className='center-card-notice-text'>
            {this.props.children}
          </p>
        </div>
      </div>
    );
  }
}

CenterCardNotice.propTypes = { children: PropTypes.any };

class CenterCardBody extends React.Component {
  render() {
    return (
      <div className='center-card-body'>
        {this.props.children}
      </div>
    );
  }
}

CenterCardBody.propTypes = { children: PropTypes.any };

class CenterCardTopRight extends React.Component {
  render() {
    const className = this.props.withNotice ? `center-card-top-right with-notice` : `center-card-top-right`;

    return (
      <div className={className}>
        {this.props.children}
      </div>
    );
  }
}

CenterCardTopRight.propTypes = { children: PropTypes.any };

class CenterCardTopLeft extends React.Component {
  render() {
    const className = this.props.withNotice ? `center-card-top-left with-notice` : `center-card-top-left`;
    return (
      <div className={className}>
        {this.props.children}
      </div>
    );
  }
}

CenterCardTopLeft.propTypes = { children: PropTypes.any };

class CenterCardContent extends React.Component {
  render() {
    const className = this.props.withNotice ? `center-card-content with-notice` : `center-card-content`;
    return (
      <div className={className}>
        {this.props.children}
      </div>
    );
  }
}

CenterCardContent.propTypes = { children: PropTypes.any, withNotice: PropTypes.bool };

class CenterCardTitle extends React.Component {
  render() {
    return (
      <div className='center-card-title'>
        {this.props.children}
      </div>
    );
  }
}

CenterCardTitle.propTypes = { children: PropTypes.any };

class CenterCardSubtitle extends React.Component {
  render() {
    return (
      <div className='center-card-subtitle'>
        {this.props.children}
      </div>
    );
  }
}

CenterCardSubtitle.propTypes = { children: PropTypes.any };

class CenterCardDetail extends React.Component {
  render() {
    if (!this.props.content) {
      return null;
    }

    return (
      <div className='center-card-detail'>
        <span><strong>{this.props.prefix}</strong> {this.props.content}</span>
      </div>
    );
  }
}

CenterCardDetail.propTypes = {
  content: PropTypes.string,
  prefix: PropTypes.string,
};

class CenterCardFooterCenter extends React.Component {
  render() {
    return (
      <div className='center-card-footer-center'>
        {this.props.children}
      </div>
    );
  }
}

CenterCardFooterCenter.propTypes = {
  children: PropTypes.any,
  onClick: PropTypes.func,
  className: PropTypes.string,
};

class CenterCardFooter extends React.Component {
  render() {
    return (
      <div className='center-card-footer'>
        {this.props.children}
      </div>
    );
  }
}

CenterCardFooter.propTypes = { children: PropTypes.any };

class CenterCardFooterDetail extends React.Component {
  render() {
    return (
      <div className='center-card-footer-detail'>
        {this.props.children}
      </div>
    );
  }
}

CenterCardFooterDetail.propTypes = { children: PropTypes.any };

export {
  CenterCard,
  CenterCardFooterDetail,
  CenterCardFooter,
  CenterCardFooterCenter,
  CenterCardTopRight,
  CenterCardTopLeft,
  CenterCardContent,
  CenterCardImage,
  CenterCardNotice,
  CenterCardTitle,
  CenterCardSubtitle,
  CenterCardBody,
  CenterCardDetail,
};
