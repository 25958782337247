import React from 'react';
import PropTypes from 'prop-types';

import WithUser from 'components/WithUser';
import ConditionalRender from 'components/ConditionalRender';

class RequireHiringManager extends React.Component {
  render() {
    return (
      <ConditionalRender
        shouldRender={
          !!this.props.user &&
          (this.props.user.hiringManager || this.props.user.admin)
        }
      >
        {this.props.children}
      </ConditionalRender>
    );
  }
}

RequireHiringManager.propTypes = {
  user: PropTypes.object,
  children: PropTypes.any.isRequired,
};

export default WithUser(RequireHiringManager);
