import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import WithTrackAction from 'components/WithTrackAction';

import 'stylesheets/application/button.less';

class Button extends React.Component {
  constructor(props) {
    super(props);

    this.onClick = this.onClick.bind(this);
  }

  onClick() {
    if (this.props.disabled) {
      return;
    }

    if (this.props.trackRequest) {
      this.props.track(this.props.trackRequest);
    }

    if (this.props.onClick) {
      this.props.onClick();
    }
  }

  render() {
    const { track, displayType, tagType, ...props } = this.props;
    const className = `brand-button brand-button-${displayType || `default`}`;

    const typeMap = {
      'internalLink': Link,
      'externalLink': `a`,
    };

    const Tag = typeMap[tagType] || `button`;

    return (
      <Tag className={className} onClick={this.onClick} {...props}>
        {this.props.children}
      </Tag>
    );
  }
}

Button.propTypes = {
  children: PropTypes.any,
  trackRequest: PropTypes.object,
  displayType: PropTypes.string,
  href: PropTypes.string,
  onClick: PropTypes.func,
  track: PropTypes.func.isRequired,
};

export default WithTrackAction(Button);
