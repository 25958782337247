import React from 'react';

class ChevronDown extends React.Component {
  render() {
    return (
      <svg className='chevron-down' width='11px' height='8px' viewBox='0 0 11 8' version='1.1'>
        <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
          <g transform='translate(-2362.000000, -679.000000)' stroke='#333333' strokeWidth='2'>
            <g transform='translate(1115.000000, 640.000000)'>
              <g transform='translate(0.000000, -6.000000)'>
                <polyline points='1248 45.7363281 1252.5 50.7363281 1257 45.7363281'></polyline>
              </g>
            </g>
          </g>
        </g>
      </svg>
    );
  }
}

class NotesIcon extends React.Component {
  render() {
    return (
      <svg className='notes-icon' width="18px" height="18px" viewBox="0 0 18 18" version="1.1">
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g transform="translate(-1279.000000, -958.000000)" fill="#C5C3BA" fillRule="nonzero">
            <g transform="translate(1115.000000, 640.000000)">
              <g transform="translate(0.000000, -6.000000)">
                <g transform="translate(130.000000, 307.000000)">
                  <path d="M50,17 L36,17 C34.9,17 34,17.9 34,19 L34,33 C34,34.1 34.9,35 36,35 L50,35 C51.1,35 52,34.1 52,33 L52,19 C52,17.9 51.1,17 50,17 Z M48,23 L38,23 L38,21 L48,21 L48,23 Z M48,27 L38,27 L38,25 L48,25 L48,27 Z M45,31 L38,31 L38,29 L45,29 L45,31 Z"></path>
                </g>
              </g>
            </g>
          </g>
        </g>
      </svg>
    );
  }
}

class LinkedinIcon extends React.Component {
  render() {
    return (
      <svg className='linkedin-icon' width="18px" height="18px" viewBox="0 0 18 18" version="1.1">
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g transform="translate(-1753.000000, -1302.000000)" fill="black" fillRule="nonzero">
            <g transform="translate(1115.000000, 640.000000)">
              <g transform="translate(0.000000, -6.000000)" >
                <g transform="translate(130.000000, 307.000000)">
                  <g transform="translate(508.000000, 361.000000)">
                    <path d="M16.6366357,0 L1.32732251,0 C0.59425058,0 8.35266821e-05,0.576041763 8.35266821e-05,1.28702088 L8.35266821e-05,16.6756009 C8.35266821e-05,17.3866218 0.594459397,17.9631647 1.32732251,17.9631647 L16.6366357,17.9631647 C17.3695406,17.9631647 17.9630394,17.3862459 17.9630394,16.6756009 L17.9630394,1.28702088 C17.9630394,0.57625058 17.3695406,0 16.6366357,0 Z M5.44593968,15.0363898 L2.73186543,15.0363898 L2.73186543,6.9262413 L5.44593968,6.9262413 L5.44593968,15.0363898 Z M4.08917401,5.81825986 L4.07092343,5.81825986 C3.16077494,5.81825986 2.57078422,5.19581903 2.57078422,4.41655684 C2.57078422,3.62159165 3.17810673,3.0160232 4.10629698,3.0160232 C5.03457077,3.0160232 5.60555916,3.62142459 5.62360093,4.41655684 C5.62360093,5.19598608 5.03477958,5.81825986 4.08917401,5.81825986 Z M15.2293782,15.0363898 L12.516181,15.0363898 L12.516181,10.6971787 C12.516181,9.60686311 12.1230626,8.86289095 11.1418747,8.86289095 C10.3912622,8.86289095 9.9456891,9.36480278 9.75002784,9.84938283 C9.67752668,10.0224501 9.65956845,10.2645104 9.65956845,10.5062367 L9.65956845,15.0362227 L6.94687239,15.0362227 C6.94687239,15.0362227 6.98237123,7.68675174 6.94687239,6.92611601 L9.65998608,6.92611601 L9.65998608,8.07590255 C10.0201531,7.52341531 10.663768,6.7352993 12.1045615,6.7352993 C13.8903619,6.7352993 15.2293782,7.89377262 15.2293782,10.386 L15.2293782,15.0363898 Z M9.65933411,8.14866752 C9.67043411,8.13046752 9.68593411,8.10856752 9.70133411,8.08726752 L9.70133411,8.14866752 L9.65933411,8.14866752 Z"></path>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </svg>
    );
  }
}

class MapIcon extends React.Component {
  render() {
    return (
      <svg width="11px" height="14px" viewBox="0 0 11 14" version="1.1">
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g transform="translate(-5594.000000, -1971.000000)" fill="#333333">
            <g transform="translate(5015.000000, 1636.000000)">
              <g transform="translate(0.000000, -5.000000)">
                <g transform="translate(528.000000, 153.000000)">
                  <g id="Group-4" transform="translate(51.000000, 184.000000)">
                    <path d="M5.47342995,3 C8.50241546,3 11,5.3 11,8.15 C11,10.85 7.54589372,15.35 5.47342995,17 C3.45410628,15.3 0,10.9 0,8.15 C0,5.3 2.44444444,3 5.47342995,3 Z M5.47342995,6.05 C6.74879227,6.05 7.75845411,7 7.75845411,8.15 C7.75845411,9.35 6.74879227,10.3 5.47342995,10.3 C4.25120773,10.3 3.24154589,9.35 3.24154589,8.15 C3.24154589,7 4.25120773,6.05 5.47342995,6.05 Z" id="Shape"></path>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </svg>
    );
  }
}

class UserIcon extends React.Component {
  render() {
    return (
      <svg width="12px" height="14px" viewBox="0 0 12 14" version="1.1">
        <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g transform="translate(-5711.000000, -1971.000000)" fill="#000000" fillRule="nonzero">
            <g transform="translate(5015.000000, 1636.000000)">
              <g transform="translate(0.000000, -5.000000)">
                <g transform="translate(528.000000, 153.000000)">
                  <g transform="translate(168.000000, 184.000000)">
                    <g transform="translate(0.000000, 3.000000)">
                      <circle cx="5.83333333" cy="2.91666667" r="2.91666667"></circle>
                      <path d="M11.6666667,10.6666 L11.6666667,13.9997667 L0,13.9997667 L0,10.6666 C0,9.74668333 0.326783333,8.91345 0.854,8.31075 C1.3825,7.70665 2.1105,7.33343333 2.91666667,7.33343333 L8.75,7.33343333 C10.3612833,7.33331667 11.6666667,8.82665 11.6666667,10.6666 Z" id="Path"></path>
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </svg>
    );
  }
}

class TagIcon extends React.Component {
  render() {
    return (
      <svg className="self-padded" width="16px" height="14px" viewBox="0 0 24 24">
        <path fill="#000000" d="M5.5,7A1.5,1.5 0 0,1 4,5.5A1.5,1.5 0 0,1 5.5,4A1.5,1.5 0 0,1 7,5.5A1.5,1.5 0 0,1 5.5,7M21.41,11.58L12.41,2.58C12.05,2.22 11.55,2 11,2H4C2.89,2 2,2.89 2,4V11C2,11.55 2.22,12.05 2.59,12.41L11.58,21.41C11.95,21.77 12.45,22 13,22C13.55,22 14.05,21.77 14.41,21.41L21.41,14.41C21.78,14.05 22,13.55 22,13C22,12.44 21.77,11.94 21.41,11.58Z" />
      </svg>
    );
  }
}

class DollarSignIcon extends React.Component {
  render() {
    return (
      <svg className="self-padded" width="16px" height="16px" viewBox="0 0 24 24" preserveAspectRatio="none">
        <path fill="#000000" d="M3.5,15H9C9,16.08 10.37,17 12,17C13.63,17 15,16.08 15,15C15,13.9 13.96,13.5 11.76,12.97C9.64,12.44 7,11.78 7,9C7,7.21 8.47,5.69 10.5,5.18V3H13.5V5.18C15.53,5.69 17,7.21 17,9H15C15,7.92 13.63,7 12,7C10.37,7 9,7.92 9,9C9,10.1 10.04,10.5 12.24,11.03C14.36,11.56 17,12.22 17,15C17,16.79 15.53,18.31 13.5,18.82V21H10.5V18.82C8.47,18.31 7,16.79 7,15Z" />
      </svg>
    );
  }
}

class CheckboxIcon extends React.Component {
  render() {
    return (
      <svg className='checkbox-icon' width="20px" height="20px" viewBox="0 0 20 20" version="1.1">
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g transform="translate(-1279.000000, -4013.000000)" fill="#FFFFFF" stroke="#C0C0C0">
            <g transform="translate(1115.000000, 3625.000000)">
              <g transform="translate(0.000000, -5.000000)">
                <g transform="translate(130.000000, 119.000000)">
                  <g transform="translate(34.000000, 269.000000)">
                    <rect x="0.5" y="5.5" width="19" height="19" rx="4"></rect>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </svg>
    );
  }
}

class CheckboxCheckedIcon extends React.Component {
  render() {
    return (
      <svg className='checkbox-checked-icon' width="20px" height="20px" viewBox="0 0 20 20" version="1.1">
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g transform="translate(-1279.000000, -4768.000000)">
            <g transform="translate(1115.000000, 4620.000000)">
              <g transform="translate(0.000000, -5.000000)">
                <g transform="translate(130.000000, 119.000000)">
                  <g transform="translate(34.000000, 29.000000)">
                    <g transform="translate(0.000000, 5.000000)">
                      <rect fill="#086A66" x="0" y="0" width="20" height="20" rx="4"></rect>
                      <polyline stroke="#FFFFFF" strokeWidth="2" points="5 10.1585366 9.40429129 14 16 5"></polyline>
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </svg>
    );
  }
}

class CloseIcon extends React.Component {
  render() {
    return (
      <svg className='close-icon' width="12px" height="10px" viewBox="0 0 12 10" version="1.1">
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g transform="translate(-1434.000000, -1946.000000)" stroke="#8D8C8C">
            <g transform="translate(1115.000000, 1635.000000)">
              <g transform="translate(0.000000, -5.000000)">
                <g transform="translate(303.000000, 299.000000)">
                  <path d="M22,22 L27,17 L22,22 L17,17 L22,22 Z M22,22 L17,27 L22,22 L27,27 L22,22 Z"></path>
                </g>
              </g>
            </g>
          </g>
        </g>
      </svg>
    );
  }
}

export {
  ChevronDown,
  CheckboxIcon,
  CheckboxCheckedIcon,
  NotesIcon,
  LinkedinIcon,
  MapIcon,
  UserIcon,
  CloseIcon,
  TagIcon,
  DollarSignIcon,
};
