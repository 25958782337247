import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { values, sortBy } from 'lodash';
import { compose } from 'recompose';
import moment from 'moment';

import { Table } from 'react-bootstrap';

import WithResourceActions from 'components/WithResourceActions';
import LoggedInPage from 'components/LoggedInPage';

const getStatusFromSubmittalOutcome = intro => {
  switch (intro.submittalOutcomeC) {
    case `Candidate Presented`:
    case `Candidate Presented Reminder`:
      return `Candidate Presented to Company `;
    case `Company Accepted/Requested Intro`:
      return `Company Requested Introduction`;
    case `Closed - No Longer Available`:
      return `Candidate No Longer Available`;
    default:
      return intro.submittalOutcomeC;
  }
};

const TalentIntros = props => (
  <Table>
    <tr>
      <th>Name</th>
      <th>Title at Current Company</th>
      <th>Status</th>
      <th>Date</th>
    </tr>
    {sortBy(values(props.talentIntros), intro => intro.lastModifiedDate).reverse().map(intro => (
      <tr key={intro.id}>
        <td><a
          href={intro.candidateLinkedinUrl}
          className='linkedin-link'
          target='_blank'
          rel='noopener noreferrer'
        >
          {intro.candidateName}
        </a></td>
        <td>{intro.candidateTitle} at {intro.candidateCompanyName}</td>
        <td>{getStatusFromSubmittalOutcome(intro)}</td>
        <td>{intro.submittalOutcomeDate ? moment(intro.submittalOutcomeDate).format(`MMMM D, YYYY`) : ``}</td>
      </tr>
    ))}
  </Table>
);

TalentIntros.propTypes = {
  talentIntros: PropTypes.object,
};

const withRedux = connect(
  state => ({
    talentIntros: state.talentIntros,
  })
);

export default compose(
  WithResourceActions,
  LoggedInPage,
  withRedux
)(TalentIntros);
