import React from 'react';
import PropTypes from 'prop-types';
import { withProps } from 'recompose';
import { connect } from 'react-redux';
import { compose } from 'redux';

import { createMessage, clearMessages, userReceived } from 'actions/actions';
import { push } from 'connected-react-router';

import WithResourceActions from 'components/WithResourceActions';
import Button from 'components/Button';
import WithUser from 'components/WithUser';

import 'stylesheets/application/offboarding-message.less';

/**
 * Once a candidate has completed the offboarding flow, they may
 * at some point want to re-instate their candidacy. This message
 * will show whenever they land on Careers while their profile is
 * in an offboarded state.
 */

class OffboardingMessage extends React.Component {
  componentDidMount() {
    const { user, createMessage } = this.props;
    const OffboardingMessageContent = this.getOffboardingMessageContent();

    if (user && user.offboarded && !!user.offboardingReason) {
      createMessage({
        body: OffboardingMessageContent,
        dismissable: true,
        type: `warning`,
        expires: false,
      });
    }
  }

  getOffboardingMessageContent() {
    return (
      <div className="offboarding-message">
        <div>Your profile is not currently being shared with hiring managers at companies in the First Round Community.</div>
        <div className="submit">
          <Button displayType="primary" type='submit' onClick={this.props.onSubmit}>
            Reactivate My Profile
          </Button>
        </div>
      </div>
    );
  }

  render() {
    return null;
  }
}

OffboardingMessage.propTypes = {
  createMessage: PropTypes.func.isRequired,
  clearMessages: PropTypes.func.isRequired,
  updateResource: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  user: PropTypes.object,
};

const onSubmit = props => () => {
  return props.updateResource({
    url: `/users/${props.user.id}`,
    store: `users`,
    user: {
      status: `pending`,
      offboarded: false,
      offboarding_reason: null,
      offboarding_reason_other: null,
      sharing_allowed: true,
    },
  }).then((user) => {
    props.userReceived(user);
    props.clearMessages();
    props.push(`/info`);
    props.createMessage({
      body: `Thanks for letting us know! Please review your profile and ensure everything is up to date.`,
      dismissable: true,
      type: `success`,
      expires: true,
    });
  });
};

const withRedux = connect(
  null,
  dispatch => ({
    createMessage: (payload) => dispatch(createMessage(payload)),
    clearMessages: () => dispatch(clearMessages()),
    push: (location) => dispatch(push(location)),
    userReceived: (user) => dispatch(userReceived(user)),
  })
);

export default compose(
  WithUser,
  WithResourceActions,
  withRedux,
  withProps(props => ({ onSubmit: onSubmit(props) }))
)(OffboardingMessage);
