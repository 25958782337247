import React from 'react';
import PropTypes from 'prop-types';

class NonRouteNavItem extends React.Component {
  render() {
    return (
      <li role="presentation">
        <a href={this.props.to}>{this.props.children}</a>
      </li>
    );
  }
}

NonRouteNavItem.propTypes = {
  to: PropTypes.string,
  children: PropTypes.node,
};

export default NonRouteNavItem;
